import './list.scss';
import { useParams } from 'react-router-dom';

import Datatable from '../../components/datatable/Datatable';

const List = ({ what }) => {

  const params = useParams();
  console.log(`params: \n`,params)

  return (
    <div className='list'>

        <Datatable what={what} />

    </div>
  )
}

export default List;