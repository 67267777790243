import axios from 'axios';
//import jwtDecode from 'jwt-decode';

const AGING = 60000; // msec

const config = {
    headers:{
      "content-type": 'application/json;charset=utf-8'
    }
};

const getAuthConfig = (accessToken) => {
    // return authorization header with jwt token
    // let accessToken = sessionStorage.getItem('accessToken');
    
    if (accessToken) {
        return { ...config, headers: {...config.headers,
                                         authorization: `Bearer ${accessToken}` }}
    } else {
        return config;
    };
    
}

export const getGradesByExam = async(examId) => {

        try {
            let response = await axios.get(`${window.apiBasePath}/grades/${examId}`);
            
            return response.data;
        } catch (err) {

            console.log(err);
            return [];
        }

}
export const getMissionsToGradeByExam = async(examId,accessToken) => {

        try {
            let response = await axios.get(`${window.apiBasePath}/grades/missions/by-exam/${examId}`,
            getAuthConfig(accessToken));
            
            return response.data;
        } catch (err) {

            console.log(err);
            return [];
        }

}

export const getGradesByExamAndStudent = async(examId,studentId,accessToken) => {

        try {
            let response = await axios.get(`${window.apiBasePath}/grades/missions/by-exam-and-student/${examId}/${studentId}`,
            getAuthConfig(accessToken));
            
            return response.data;
        } catch (err) {

            console.log(err);
            return [];
        }

}

export const getGradesByStudentNotCurrUser = async(studentId, accessToken) => {

    try {
        let response = await axios.get(`${window.apiBasePath}/grades/student/${studentId}`,
                    getAuthConfig(accessToken));
                                
        return response.data;
    } catch (err) {

        console.log(err.message);
        return [];
    }

}
export const getGradesByStudent = async(studentId, gradeList, setGradeList, 
                                         controller,accessToken) => {

    if (gradeList.list.length > 0 && gradeList.studentId === studentId && Date.now() - gradeList.updated < AGING) {
        return gradeList.list;
    }

       try {
            let response = await axios.get(`${window.apiBasePath}/grades/student/${studentId}`,
                        getAuthConfig(accessToken));
                        //{signal: controller.signal},getAuthConfig(accessToken));
            
            
            setGradeList(response.data,studentId)
            return response.data;
        } catch (err) {

            console.log(err.message);
            return [];
        }

}

export const deleteGrade = async(...args) => {

    //console.log(`args:\n`,args)
    // examData - array
    const [gradeId, accessToken ] = args;

    try {
        await axios.delete(`${window.apiBasePath}/grades/missions/grade/${gradeId}`,
                     getAuthConfig(accessToken));
                   
        
        return {success: true};
    } catch (err) {

        console.log(err.message);
        return {success: false, message: err.message};
    }

}

export const updateGrades = async(...args) => {

    //console.log(`args:\n`,args)
    // missionData - array
    const [gradeData, accessToken ] = args;

    try {
        await axios.put(`${window.apiBasePath}/grades/missions/grades`,gradeData,
                     getAuthConfig(accessToken));
                   
        
        return {success: true};
    } catch (err) {

        console.log(err.message);
        return {success: false, message: err.message};
    }

}

export const addGrades = async(...args) => {

    //console.log(`args:\n`,args)
    // missionData - array
    const [gradeData, accessToken ] = args;

    try {
        await axios.post(`${window.apiBasePath}/grades/missions/grades`,gradeData,
                     getAuthConfig(accessToken));
                   
        
        return {success: true};
    } catch (err) {

        console.log(err.message);
        return {success: false, message: err.message};
    }

}