import './ListsOfGrades.scss';
import {useState, useEffect} from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Box from '@mui/material/Box';

import { useCourseListStore } from "../../context/courseListStore";
import { useShallow } from "zustand/react/shallow";

import { getExamsByCourse } from '../../services/exams';

import ShowGradesByExam from './ShowGradesByExam';
import Select from './../../components/form/Select';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ListsOfGrades = () => {

  const { courseList, setCourseList, examList, setExamList,
              course, setCourse } = useCourseListStore(
    useShallow((state) => ({ courseList: state.courseList, 
                          setCourseList: state.setCourseList,
                               examList: state.examList,
                            setExamList: state.setExamList,
                                 course: state.course,
                              setCourse: state.setCourse }))
  );

  useEffect(() => {

    async function fillExamsIfThereIsCourse() {

      if (Object.keys(course).length !== 0) { // if course is not an empty obj
        
        await getExamsByCourse(course.id, examList, setExamList);
        
      }
    }

    fillExamsIfThereIsCourse();

  }, [])

  const selectCourseDesc = {
    id: 0,
    field: "select",
    label: "Choose the Course:",
    fName: "course",
    type: "select",
    fillFromField: "id",
    placeholder: "",
    options: courseList.list.length===0?[{value:"",text:"-- Choose from the List --"}]:
    [{value:"",text:"-- Choose from the List --"},...courseList.list.map(c=>{return {value:c.id,text:c.title}})],
    getMoreOptions: courseList.list.length===0?["getCourseList","courses.js","id","title",[courseList,setCourseList]]:[],
    required: true,
    dataType: "number"
  }



    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleCourseChange = async (e) => {
      let val = +e.target.value;
      if (val === course.id) return;

      await getExamsByCourse(val, examList, setExamList);
      setCourse(courseList.list.filter(c => c.id === val)[0]);
    }

  return (
    <div className="listsOfGrades"> 
        <div className="title">
            <span>Exam and Assignment Grades</span>
            <Select className="formInput" selectDesc={selectCourseDesc} 
                setFormDataOnChange={handleCourseChange} width="40%"
                data={Object.keys(course).length!==0 ? {id: course.id } : {id: ''}}
            /> 
        </div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="By Exam" {...a11yProps(0)} className="tab" />
                <Tab label="By Student" {...a11yProps(1)} className="tab" />
                <Tab label="???" {...a11yProps(2)} className="tab" />
            </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
            <ShowGradesByExam />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
            By Student
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
            ???
        </CustomTabPanel>
    </div>
  )
}

export default ListsOfGrades