import axios from 'axios';
//import jwtDecode from 'jwt-decode';

const aging = 60000; // msec

const config = {
    headers:{
      "content-type": 'application/json;charset=utf-8'
    }
};

const getAuthConfig = (accessToken) => {
    // return authorization header with jwt token
    //let accessToken = sessionStorage.getItem('accessToken');
    
    if (accessToken) {
        return { ...config, headers: {...config.headers,
                                         authorization: `Bearer ${accessToken}` }}
    } else {
        return config;
    };
    
}

export const getMissionsByExam = async(examId) => {

        try {
            let response = await axios.get(`${window.apiBasePath}/missions/by-exam/${examId}`);
            
            return response.data;
        } catch (err) {

            console.log(err);
            return [];
        }

}

export const updateMissions = async(...args) => {

    //console.log(`args:\n`,args)
    // missionData - array
    const [missionData, accessToken ] = args;

    try {
        await axios.put(`${window.apiBasePath}/exams/missions`,missionData,
                     getAuthConfig(accessToken));
                   
        
        return {success: true};
    } catch (err) {

        console.log(err.message);
        return {success: false, message: err.message};
    }

}

export const addMissions = async(...args) => {

    //console.log(`args:\n`,args)
    // missionData - array
    const [missionData, accessToken ] = args;

    try {
        await axios.post(`${window.apiBasePath}/exams/missions`,missionData,
                     getAuthConfig(accessToken));
                   
        
        return {success: true};
    } catch (err) {

        console.log(err.message);
        return {success: false, message: err.message};
    }

}

export const deleteMission = async(...args) => {

    //console.log(`args:\n`,args)
    // examData - array
    const [rowId, accessToken ] = args;

    try {
        await axios.delete(`${window.apiBasePath}/exams/mission/${rowId}`,
                     getAuthConfig(accessToken));
                   
        
        return {success: true};
    } catch (err) {

        console.log(err.message);
        return {success: false, message: err.message};
    }

}

