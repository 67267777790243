import { create } from 'zustand';

export const useStudentStore = create((set) => ({

  studentDetails: {},
  examList: {list:[],updated:0,studentId: undefined},
  gradeList: {list:[],updated:0,studentId: undefined},
  course: sessionStorage.getItem('class-course') && sessionStorage.getItem('class-course') !== 'undefined' ? 
            JSON.parse(sessionStorage.getItem('class-course')) : {},


  setCourse: (currCourse) => set({ course: currCourse }),
  
  setStudentDetails: (userDetails) => set({ studentDetails:userDetails}), 
  setExamList: (list, studentId) => set({ examList: {list, studentId, updated:Date.now()} }), 
  setGradeList: (list, studentId) => set({ gradeList: {list, studentId, updated:Date.now()} }), 
   
  removeExam: (id) => set((state) => ({ 
    examList: state.examList.filter(ex => ex.id !== id) 
  })), 
  addExam: (exam) => set((state) => ({ 
    examList: [...state.examList, exam] 
  })), 

  emptyStore: () => set({
    studentDetails:{},
    course: {},
    examList: {list:[],updated:0,studentId: undefined},
    gradeList: {list:[],updated:0,studentId: undefined}
  })

}))