import './styles/TrySidebar2.scss';
import Sidebar2 from './Sidebar2';
import { BrowserRouter as Router2, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import News from "./pages/News";
import Performance from "./pages/Performance";
import Transactions from "./pages/Transactions";
import Settings from "./pages/Settings";
import Support from "./pages/Support";

const TrySidebar2 = () => {
  return (

        <div className="TrySidebar2">
            <Sidebar2 />
            
        </div>
  
  )
}

export default TrySidebar2