import './Gallery.scss';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleXmark
} from '@fortawesome/free-solid-svg-icons';

const Gallery = ({ images }) => {

  const [ slideNum, setSlideNum ] = useState(0);
  const [ openModal, setOpenModal ] = useState(false);
  

  const handleOpenModal = (index) => {
    setSlideNum(index);
    setOpenModal(true);
  }

  const handleCloseModal = () => setOpenModal(false);

  const prevSlide = () => 
    slideNum === 0 ? setSlideNum(images.length - 1) : setSlideNum(slideNum - 1);

  const nextSlide = () => 
    slideNum === images.length-1 ? setSlideNum(0) : setSlideNum(slideNum + 1);

  return (
    <div className="gallery">

        {openModal &&
            <div className="sliderWrap">
                <FontAwesomeIcon icon={faCircleXmark} className="btnClose"
                                 onClick={handleCloseModal} />
                <FontAwesomeIcon icon={faCircleChevronLeft} className="btnPrev" 
                                 onClick={prevSlide} />
                <FontAwesomeIcon icon={faCircleChevronRight} className="btnNext"
                                 onClick={nextSlide} />

                <div className="fullScreenImg">
                    <img src={require(`../../assets/img/${images[slideNum].img_address}`)} 
                         alt={images[slideNum].img_alt} />
                </div>
            </div>
        }

        <div className="galleryWrap">

        {
            images.length > 0 ? images.map((slide,index)=>{
                return (
                    <div className="single" key={index}
                    onClick={() => handleOpenModal(index)} >
                        <img src={require(`../../assets/img/${slide.img_address}`)} 
                             alt={slide.img_alt} />
                    </div>
                )
            }) : <h3 className="noImages">No images, alas</h3>
        }

        </div> {/* galleryWrap */}

    </div>
  )
}

export default Gallery;