import './Login.scss';
import Input from '../../components/form/Input';
import MainForm from '../../components/form/MainForm';

import { useState, useContext } from 'react';
import { userContext } from '../../context/userContext';
import { useNavigate, Link } from 'react-router-dom';

import Grid from "@mui/material/Grid";

import { login } from '../../services/auth';
import { loginInputs } from '../../formSource';

import { useAuthStore } from "../../context/authStore";
import { useShallow } from "zustand/react/shallow";


const Login = () => {

  const { accessToken, setAccessToken } = useAuthStore(
    useShallow((state) => ({ accessToken: state.accessToken, 
                    setAccessToken: state.setAccessToken,
                        }))
);

  //const navigate = useNavigate();
  const { dispatchUser } = useContext(userContext);

  // const [formData, setFormData] = useState(
  //   loginInputs.reduce((acc,input) => {
  //     return {...acc,[input.label]:''};
  //   },{})
  // );



  // const setFormDataOnChange = (e) => {

  //   setFormData({...formData, [e.target.name]: e.target.value});

  // }

  const onSubmit = async (formData) => {

      return await login(formData, dispatchUser, setAccessToken);

  }
  /* const onSubmit = async (e) => {

    e.preventDefault();
    // we could perform here some formData validation

    console.log(`Form Data:`);
    console.log(formData);

    if (loginInputs.every(el => formData[el.label])) {
      
      await login(formData, dispatchUser)
          
      loginInputs.reduce((acc,input) => {
        return {...acc,[input.label]:''};
      },{});
      navigate('/');

      
    }

  } */

  return (
    <div className="login">
        <div className="top">
          <h1>Login</h1>
        </div>
        <div className="bottom">

          <div className="right">
            <MainForm formFields={loginInputs} data={{}} doOnSubmit={onSubmit} />
            {/* <form onSubmit={onSubmit}>

              {
                loginInputs.map((input) => { 
                  return (
                    <Input className="formInput" key={input.id}
                      inputDesc={input} setFormDataOnChange={setFormDataOnChange}
                    />
                  )
                })
              }

              <button>Send</button>
            </form> */}

            {/* <Grid container>
              <Grid item sm>
                  <Link
                      href="/forgotPassword"
                      variant="body2"
                      style={{textDecoration:"None"}}
                  >
                      Forgot password?
                  </Link>
              </Grid>
            </Grid> */}

          </div>
        </div>
        <div className="below-bottom">
              <Grid item sm>
                  <Link to="/auth/forgot-password" >
                      Forgot password?
                  </Link>
              </Grid>
            </div>
    </div>
  )
}

export default Login;