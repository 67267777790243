import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { DarkModeContextProvider } from "./context/darkModeContext";
import { StyleContextProvider } from "./context/styleContext";
import { UserContextProvider } from "./context/userContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <DarkModeContextProvider>
    <StyleContextProvider>
    <UserContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </UserContextProvider>
    </StyleContextProvider>
    </DarkModeContextProvider>
  
);

