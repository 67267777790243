import axios from 'axios';
import {logout} from './auth';

const AGING = 60000; // msec

const config = {
    headers:{
      "content-type": 'application/json;charset=utf-8'
    }
};

const getAuthConfig = (accessToken) => {
    // return authorization header with jwt token
    // let accessToken = sessionStorage.getItem('accessToken');
    
    if (accessToken) {
        return { ...config, headers: {...config.headers,
                                         authorization: `Bearer ${accessToken}` }}
    } else {
        return config;
    };
    
}

export const getCourseList = async(courseList,setCourseList) => {

    /* console.log(`args\n`,args)
    const [courseList,setCourseList] = args; */

    /* if (courseList?.list?.length > 0 && Date.now() - courseList?.updated < AGING) {
        return courseList?.list;
    } */
    
    try {
        let response = await axios.get(`${window.apiBasePath}/courses`);
        setCourseList(response.data);
        return response.data;
    } catch (err) {
        
        console.log(err);
        return [];
    }

}

export const getCourseStudents = async(courseId,setStudentList) => {

        try {
            let response = await axios.get(`${window.apiBasePath}/students/${courseId}/false`);
            setStudentList(response.data)
            return response.data;
        } catch (err) {

            console.log(err);
            return [];
        }

}

export const getCourseByStudent = async(studentId, setCourse, controller,accessToken,
    dispatchUser, role, emptyAuthStore, emptyStudentStore) => {

    
    try {
        let response = await axios.get(`${window.apiBasePath}/courses/student/${studentId}`, 
                   getAuthConfig(accessToken));
                  //{signal: controller.signal}, getAuthConfig(accessToken));
        
        setCourse(response.data);

        return response;
    } catch (err) {

        console.log(err)
        if (err.response) {
            if (err.response.data.message === `Your session has been expired`) {

                logout(dispatchUser,role,emptyAuthStore,emptyStudentStore,
                    accessToken)

            }
        }
        console.log(err.message);
        return {};
    }

}

export const getNeverLoggedCourseStudents = async(courseId) => {

        try {
            let response = await axios.get(`${window.apiBasePath}/students/${courseId}/true`);
            
            return response;
        } catch (err) {

            console.log(err);
            return [];
        }

}

export const checkCourseTempPasswd = async(courseId,passwd,courseList) => {

    //const courseList = await getCourseList();
    const currCourse = courseList.filter(course => course.id === courseId);
    return currCourse.temp_passwd === passwd;

}

export const getRandAvatar = async (searchArr) => {

    let searchStr = ',' + searchArr.join(',|,') + ',';

    try {

        let response = await axios.get(`${window.apiBasePath}/avatars/${searchStr}`, 
                                        getAuthConfig());

        console.log('response:');
        console.log(response);
        
    } catch(err)  {
        console.log(err)
        if (err.response) {
            console.log('err.response')
            // The client was given an error response (5xx, 4xx)
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
        } else if (err.request) {
            console.log('err.request')
            // The client never received a response, and the request was never left
        } else {
            console.log('other err')

        }
    }


    sessionStorage.removeItem('accessToken');


}

