import { useContext, useEffect, useRef } from 'react';
import { Routes, Route  } from 'react-router-dom';

import Sidebar from './components/sidebar/Sidebar';
import Navbar from './components/navbar/Navbar';

import FindYourself from './pages/findYourself/FindYourself';
import FirstLogin from './pages/login/FirstLogin';

import Home from './pages/home/Home';
import HomeStudent from './pages/home/HomeStudent';

import InsertExamsAndGrades from './pages/insertExamsAndGrades/InsertExamsAndGrades';
import ListsOfGrades from './pages/listsOfGrades/ListsOfGrades';
import ListsOfStudentGrades from './pages/listsOfGrades/singleStudentGrades/ListsOfStudentGrades';
import ViewListsOfGrades from './pages/listsOfGrades/viewCourseGrades/ViewListsOfGrades';

import PythonPractice from './pages/python/PythonPractice';

import GameGallery from './pages/games/GameGallery';
import SpecificGames from './pages/games/SpecificGames';
import Jeopardy from './pages/games/jeopardy/Jeopardy';
import List from './pages/list/List';

import { AdminRoutes, NotLoggedRoutes, StudentRoutes, ViewerRoutes } 
from './routes';
import ForgotPassword from './pages/login/ForgotPassword';
import ResetPassword from './pages/login/ResetPassword';
import Login from './pages/login/Login';
import SignUp from './pages/signUp/SignUp';

import New from './pages/new/New';
import ShowImages from './pages/showImages/ShowImages';
import Single from './pages/single/Single';
import TryImages from './pages/tryImages/TryImages';
import TrySidebar from './components/trySidebar/TrySidebar';
import TrySidebar2 from './components/trySidebar/TrySidebar2';
import TryUseImperativeHandleParent from './pages/tryUseImperativeHandle/TryUseImperativeHandleParent';
import UnderConstruction from './pages/underConstruction/UnderConstruction';

import { getRole } from './services/auth';

import { userInputs, loginInputs, productInputs } from './formSource';

import { darkModeContext } from './context/darkModeContext';
import { userContext } from './context/userContext';
import StyleContext from './context/styleContext';

import './style/App.scss';
import './style/dark.scss';

/* window.apiBasePath="/class"; */
 /* window.apiBasePath="http://localhost:5600"; */
window.apiBasePath="https://class-api.leonardoschool.co.il";

function App() {

  const { sideBarCollapse } = useContext(StyleContext);
  const { darkMode } = useContext(darkModeContext);
  const { role, dispatchUser } = useContext(userContext);

  const appRenders = useRef(0);
  appRenders.current++;
  // console.log(`appRenders = ${appRenders.current}`);

  useEffect(() => {
    dispatchUser({type:getRole().toUpperCase()})
  },[dispatchUser])

  const style = {
    navContentContainer: {
      /* flex: sideBarCollapse ? '20' : '12' */
      flexGrow: sideBarCollapse ? '20' : '12',
      flexShrink: sideBarCollapse ? '1' : '2',
      flexBasis: sideBarCollapse ? '95%' : '91%'
    }
  }

  return (
      
    <div className={ darkMode ? "app dark" : "app" }>

      <div className="page-container">

        <Sidebar />

        <div className={`nav-content-container ${sideBarCollapse ? 'wide' : 'narrow'}`}
             style={style.navContentContainer}>

          <Navbar />

          <Routes>

          <Route element={ <NotLoggedRoutes role={role} /> }>

<Route path="/login" element={ <Login inputs={loginInputs} /> } />
<Route path="/sign-up" element={ <SignUp inputs={loginInputs} /> } />
<Route path="/find-yourself" element={ <FindYourself inputs={loginInputs} /> } />

<Route path="/first-login" element={ <FirstLogin /> } />

<Route path="/auth">

  <Route path="forgot-password" element={ <ForgotPassword /> } />
  <Route path="reset-password" element={ <ResetPassword /> } />
</Route>
</Route>

<Route element={ <AdminRoutes role={role} /> }>


<Route path="/" element={ <Home /> } />

<Route path="/try-images" element={ <TryImages /> } />
<Route path="/try-imp" element={ <TryUseImperativeHandleParent /> } />
<Route path="/try-sidebar" element={ <TrySidebar /> } />
<Route path="/try-sidebar2" element={ <TrySidebar2 /> } />

<Route path="/games">

<Route index element={ <GameGallery /> } />

<Route path="jeopardy">

  <Route index element={ <Jeopardy /> } />
  {/* <Route index element={ <SpecificGames game='jeopardy' /> } /> */}
  <Route path=":gameId" element={ <Jeopardy /> } />
</Route>

</Route>

<Route path="/images">

<Route index element={ <ShowImages /> } />
<Route path=":schoolId" element={ <Single /> } />
<Route path="new" element={ <New inputs={userInputs} title="Add New School" /> } />

</Route>
<Route path="/schools">

<Route index element={ <List what='schools' /> } />
<Route path=":schoolId" element={ <Single /> } />
<Route path="new" element={ <New inputs={userInputs} title="Add New School" /> } />

</Route>
<Route path="/students">

<Route index element={ <List what='students' /> } />
<Route path=":studentId" element={ <Single /> } />
<Route path="new" element={ <New inputs={userInputs} title="Add New Student" /> } />

</Route>
<Route path="/users">

<Route index element={ <List what='users' /> } />
<Route path=":userId" element={ <Single /> } />
<Route path="new" element={ <New inputs={userInputs} title="Add New User" /> } />


</Route>

<Route path="/products">

<Route index element={ <List /> } />
<Route path=":productId" element={ <Single /> } />
<Route path="new" element={ <New inputs={productInputs} title="Add New Product" /> } />

</Route>

<Route path="/exams">

<Route index element={ <List what="exams" /> } />
<Route path="by-course/:courseId" element={ <List what="exams" link="/exams/by-course/:courseId" /> } />
<Route path=":examId" element={ <Single /> } />
<Route path="insert" element={ <InsertExamsAndGrades /> } />
<Route path="grades" element={ <ListsOfGrades /> } />
<Route path="new" element={ <New inputs={productInputs} title="Add New Exam" /> } />

</Route>

<Route path="/missions">

<Route index element={ <List what="missions" /> } />
<Route path="by-exam/:examId" element={ <List what="missions" link="/missions/by-exam/:examId" /> } />
<Route path=":missionId" element={ <Single /> } />
<Route path="new" element={ <New inputs={productInputs} title="Add New Mission" /> } />

</Route>
/
</Route>{/* Admin Routes */}

<Route element={ <ViewerRoutes role={role} /> }>
<Route path="/viewer">
  <Route path="grades" element={ <ViewListsOfGrades /> } />
</Route>
</Route>

<Route element={ <StudentRoutes role={role} /> }>
<Route path="/student">
  <Route path="grades" element={ <ListsOfStudentGrades /> } />
  <Route path="python" element={ <PythonPractice /> } />
</Route>
</Route>

<Route path="*" element={ <UnderConstruction /> } />



          </Routes> 
        </div> {/* .nav-content-container */}
      </div> {/* .page-container */}

    </div>
  );
}

export default App;
