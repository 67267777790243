import './styles/TrySidebar.scss';
import { useState } from 'react';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ElectricalServicesOutlinedIcon from '@mui/icons-material/ElectricalServicesOutlined';
import FormatAlignJustifyOutlinedIcon from '@mui/icons-material/FormatAlignJustifyOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';

const TrySidebar = () => {

  const [ navCollapse, setNavCollapse ] = useState(false);
  const [ smallNavCollapse, setSmallNavCollapse ] = useState(false);

  return (
    <div className="TrySidebar">
        <nav className="nav">
            <div className="logo">
                <h2>Try Sidebar</h2>
                <FormatAlignJustifyOutlinedIcon className="icon largeDeviceIcon" 
                  onClick={(e) => setNavCollapse(!navCollapse)} />
                <FormatAlignJustifyOutlinedIcon className="icon smallDeviceIcon" 
                  onClick={(e) => setSmallNavCollapse(!smallNavCollapse)} />
            </div>
            <ul>
                <li>Home</li>
                <li>New Blog</li>
            </ul>
        </nav>
        <div className="SidebarContent">
            <div className={`${ smallNavCollapse ? "smallNavCollapse" : ""} SidebarContainer ${ navCollapse ? "navCollapse" : "" }`}>
                <div className="nav-option option1">
                    <SpeedOutlinedIcon className="icon" />
                    <h3>Dashboard</h3>
                </div>
                <div className="nav-option option1">
                    <PendingActionsOutlinedIcon className="icon" />
                    <h3>Articles</h3>
                </div>
                <div className="nav-option option1">
                    <ChatOutlinedIcon className="icon" />
                    <h3>Report</h3>
                </div>
                <div className="nav-option option1">
                    <SettingsOutlinedIcon className="icon" />
                    <h3>Settings</h3>
                </div>
                <div className="nav-option option1">
                    <ElectricalServicesOutlinedIcon className="icon" />
                    <h3>Logout</h3>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TrySidebar