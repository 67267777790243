import './GalleryItem.scss';
import {Link} from 'react-router-dom';

export default function GalleryItem({item}) {

  const {text,linkTo,image} = item;

  return (
    <Link to={linkTo}>
        <h3>{text}</h3>
        {
            image && 
                <img src={image.src} alt={image.alt} />
        }
    </Link>
  )
}
