import './styles/Sidebar2.scss';

import { useState } from "react";
import Icon from "./images/Icon.svg";
import Profile from "./images/profile.png";
import Dashboard from "./images/dashboard.svg";
import Transactions from "./images/transactions.svg";
import Performance from "./images/performance.svg";
import News from "./images/news.svg";
import Settings from "./images/settings.svg";
import Support from "./images/support.svg";
import { useLocation } from "react-router-dom";

const Sidebar2 = () => {
    const location = useLocation();

    const [closeMenu, setCloseMenu] = useState(false);

    const handleCloseMenu = () => {
        setCloseMenu(!closeMenu);
    };

    console.log(closeMenu);
    return (
        <div className={closeMenu === false ? "Sidebar2" : "Sidebar2 active"}>
            <div
                className={
                    closeMenu === false
                        ? "logoContainer"
                        : "logoContainer active"
                }
            >
                <img src={Icon} alt="icon" className="logo" />
                <h2 className="title">evergreen.{closeMenu} </h2>
            </div>
            <div
                className={
                    closeMenu === false
                        ? "burgerContainer"
                        : "burgerContainer active"
                }
            >
                <div
                    className="burgerTrigger"
                    onClick={() => {
                        handleCloseMenu();
                    }}
                ></div>
                <div className="burgerMenu"></div>
            </div>
            <div
                className={
                    closeMenu === false
                        ? "profileContainer"
                        : "profileContainer active"
                }
            >
                <img src={Profile} alt="profile" className="profile" />
                <div className="profileContents">
                    <p className="name">Hello, John👋</p>
                    <p>johnsmith@gmail.com</p>
                </div>
            </div>
            <div
                className={
                    closeMenu === false
                        ? "contentsContainer"
                        : "contentsContainer active"
                }
            >
                <ul>
                    <li className={location.pathname === "/try-sidebar2/" ? "active" : ""}>
                        <img src={Dashboard} alt="dashboard" />
                        <a href="/try-sidebar2/">dashboard</a>
                    </li>
                    <li
                        className={
                            location.pathname === "/try-sidebar2/transactions"
                                ? "active"
                                : ""
                        }
                    >
                        <img src={Transactions} alt="transactions" />
                        <a href="/try-sidebar2/transactions">transactions</a>
                    </li>
                    <li
                        className={
                            location.pathname === "/try-sidebar2/performance" ? "active" : ""
                        }
                    >
                        <img src={Performance} alt="Performance" />
                        <a href="/try-sidebar2/performance">performance</a>
                    </li>
                    <li
                        className={
                            location.pathname === "/news" ? "active" : ""
                        }
                    >
                        <img src={News} alt="News" />
                        <a href="/try-sidebar2/news">news</a>
                    </li>
                    <li
                        className={
                            location.pathname === "/try-sidebar2/settings" ? "active" : ""
                        }
                    >
                        <img src={Settings} alt="Settings" />
                        <a href="/try-sidebar2/settings">settings</a>
                    </li>
                    <li
                        className={
                            location.pathname === "/try-sidebar2/support" ? "active" : ""
                        }
                    >
                        <img src={Support} alt="Support" />
                        <a href="/try-sidebar2/support">support</a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar2