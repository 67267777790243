import './Sidebar.scss';
import './SidebarItem';
import { sidebarEntries } from './sidebarSource';

import { Link, useLocation } from 'react-router-dom';
import { useContext, useState, useEffect, useRef, useLayoutEffect } from 'react';

import { darkModeContext } from '../../context/darkModeContext';
import { userContext } from '../../context/userContext';
import StyleContext from '../../context/styleContext';
import SidebarItem from './SidebarItem';
import { logout } from '../../services/auth';

import { useAuthStore } from "../../context/authStore";
import { useStudentStore } from "../../context/studentStore";
import { useShallow } from "zustand/react/shallow";

import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';

const Sidebar = () => {

  const { emptyStore:emptyStudentStore } = useStudentStore(
      useShallow((state) => ({ emptyStore: state.emptyStore }))
  );

  const { emptyStore:emptyAuthStore, accessToken } = useAuthStore(
      useShallow((state) => ({ emptyStore: state.emptyStore, 
                                      accessToken: state.accessToken }))
  );

  let location = useLocation();

  const { dispatch } = useContext(darkModeContext);
  const { role, dispatchUser } = useContext(userContext);
  const { sideBarCollapse, dispatchStyle } = useContext(StyleContext);

  const style = {
    sideBarParent: {
      /* flex: sideBarCollapse ? '1' : '2', */
      flexGrow: sideBarCollapse ? '1' : '2',
      flexShrink: sideBarCollapse ? '2' : '1',
      flexBasis: sideBarCollapse ? '65px' : '140px',
      width: sideBarCollapse ? '65px' : '140px'
    }
  }

  let lastActiveIdx = Number(localStorage.getItem("lastActiveIdx"));

  const [ activeIdx, setActiveIdx ] = 
       useState((lastActiveIdx && lastActiveIdx >=0) ? lastActiveIdx 
            : sidebarEntries[role].firstActive);

  const refParent = useRef(null);
  const [ width, setWidth ] = useState(0);

  const sideBarRenders = useRef(0);
  sideBarRenders.current++;
  //console.log(`sideBarRenders = ${sideBarRenders.current}`);

  useLayoutEffect(() => {
    //console.log(`SIDEBARCOLLAPSE HAS CHANGED`)
    setWidth(refParent.current.offsetWidth);
  },[sideBarCollapse])

  const [ navCollapse, setNavCollapse ] = useState(false);
  const [ smallNavCollapse, setSmallNavCollapse ] = useState(false);


  const changeActiveIdx = (idx) => {
    setActiveIdx(idx);
    localStorage.setItem("lastActiveIdx",idx);
  }

  //Appends '/' to the start of a string if not present
  function getPath(path) {
    return !path ? "/" : path.charAt(0) !== "/" ? "/" + path : path;
  }

  useEffect(() => {

    // To change activeIdx in accordance to the new location 
    const activeItem = sidebarEntries[role].entries.findIndex(item=> 
      getPath(item.link) === getPath(location.pathname));
    changeActiveIdx(activeItem!==-1 ? activeItem 
                                    : sidebarEntries[role].firstActive);

  },[location,role])

  return (
    <div key={sideBarCollapse ? "collapsed" : "expanded"}
      className={`sidebarParent ${navCollapse ? "navCollapse" : ""} ${sideBarCollapse ? "east" : ""}`} 
                       ref={refParent} style={style.sideBarParent}>


    <div className="sidebar" style={{ width: width+"px"}}>
        <div className="top" onClick={()=>{setActiveIdx(undefined)}}>
            <Link to="/" style={{ textDecoration:"none"}}>
                <span className={`logo ${sideBarCollapse ? "narrow" : ""}`}>
                  Class
                </span>
            </Link>
            <button className={sideBarCollapse ? "east" : "west"} onClick={() => {
              dispatchStyle({type:"SIDEBAR TOGGLE COLLAPSE"})
            }}>
              {sideBarCollapse ? 
                  <EastIcon className='icon' /> :
                  <WestIcon className='icon' />}
            </button>
        </div>
        <hr />
        <div className="center">
            <ul>

                {
                   sidebarEntries[role].entries.map((entry,i) => {

                    return <SidebarItem itemDesc={entry} key={i} setActive={changeActiveIdx}
                                        logout={logout} dispatchUser={dispatchUser} 
                                        isActive={i === activeIdx} idx={i} 
                                        navCollapse={navCollapse} role={role}
                                        emptyAuthStore={emptyAuthStore} 
                                        emptyStudentStore={emptyStudentStore}
                                        accessToken={accessToken}
                                        narrow={sideBarCollapse} />
                   }) 
                }

            </ul>
        </div>
        {
          role === 'admin' &&
          <div className="bottom">
            <div className="colorOption" onClick={()=>dispatch({type:"LIGHT"})}></div>
            <div className="colorOption" onClick={()=>dispatch({type:"DARK"})}></div>
        </div>
        }
    </div>
    <div className="i-am-a-skeleton" style={{ width: width+"px"}}></div>
    </div>
  )
}

export default Sidebar;