import './Code.scss';
import { useState } from 'react';

function Code({amtOfDigits,checkCode,errMsg}) {

  const [ digits, setDigits ] = useState(new Array(amtOfDigits).fill(''));

  const onChange = (e,ix) => {

    let newVal = e.target.value[0];

    const newDigits = digits.slice(0);
    newDigits[ix] = /^[0-9]$/.test(newVal) ? newVal : '';

    setDigits(newDigits);
    if (newDigits.every(digit => digit)) {

      checkCode(Number(newDigits.join('')));
    }
    
  }
  
  const digitInputs = digits.map((_,ix) => 
  <input type="text" className="codeDigit" key={ix}
                  id={`digit${ix}`} 
                  value={digits[ix] || ''} 
                  onChange={(e) => {onChange(e,ix)}} 
                  
                  /> )


  return (
    <div className="formInput">
      <div className="digitInputs">
        { digitInputs }
      </div>
      <p className={`errMsg ${errMsg && 'visible'}`}>{errMsg || ''}</p>
    </div>
  )
}

export default Code