import './PythonPractice.scss';

import Select from '../../components/form/Select';

const PythonPractice = () => {

    const selectPracticeDesc = {
        id: 0,
        field: "select",
        label: "Choose the Pyton Practice:",
        fName: "pythonPractice",
        type: "select",
        fillFromField: "id",
        placeholder: "",
        // options: courseList.list.length===0?[{value:"",text:"-- Choose from the List --"}]:
        // [{value:"",text:"-- Choose from the List --"},...courseList.list.map(c=>{return {value:c.id,text:c.title}})],
        // getMoreOptions: courseList.list.length===0?["getCourseList","courses.js","id","title",[courseList,setCourseList]]:[],
        required: true,
        dataType: "number"
    }

  return (
    <div className=".pythonPractice">
        <div className="title">
            <span>Welcome to Python Practice. Enjoy!</span>
            <div className="selectPracticeAndExercise">

            <Select className="formInput" selectDesc={selectPracticeDesc} 
                setFormDataOnChange={'handlePracticeChange'} width="40%"
                data={"Object.keys(course).length!==0 ? {id: course.id } : {id: ''}"}
            /> 

            </div>
        </div>
    </div>
  )
}

export default PythonPractice