import './home.scss';
import Chart from '../../components/chart/Chart';
import Featured from '../../components/featured/Featured';

import Table from '../../components/table/Table';
import Widget from '../../components/widget/Widget';

const Home = () => {
  return (
    <div className="home">
        
            <div className="widgets">
                <Widget type={"user"} />
                <Widget type={"order"} />
                <Widget type={"balance"} />
                <Widget type={"earning"} />
            </div>
            <div className="charts">
              <Featured />
              <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
            </div>
            <div className="listContainer">
            <div className="listTitle">Latest Transactions</div>
              <Table />
            </div> 

    </div>
  )
}

export default Home;
