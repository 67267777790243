import './MainForm.scss';
import Input from './Input';
import Passwd from './Passwd';
import Select from './Select';

import { useState } from 'react';

import { useNavigate} from 'react-router-dom';

const MainForm = ({formFields,data,doOnSubmit}) => {

  const navigate = useNavigate();

  const [errMsg,setErrMsg] = useState('');
  const [fieldErr,setFieldErr] = useState(formFields.reduce((acc,input) => {
    return {...acc,[input.fName]:''};
  },{}));

  const [formData, setFormData] = useState(
    formFields.reduce((acc,input) => {
      return {...acc,[input.fName]:input.fillFromField && data[input.fillFromField]
        ? data[input.fillFromField] : ''};
    },{})
  );

  const setFormDataOnChange = (e) => {
    setFormData({...formData, [e.target.name]: 
      e.target.getAttribute('data-type') === "number" ?
               +e.target.value : e.target.value});

  }

  const onSubmit = async (e) => {

    e.preventDefault();
    // we could perform here some formData validation

    setErrMsg('');

    if (formFields.every(el => formData[el.fName] || !el.required)) {
      
      setFieldErr(formFields.reduce((acc,el) => {
        return {...acc,[el.fName]:''};
      },{}));
      
      let result = await doOnSubmit(formData);
          
      if (result.status) {

        setFormData(formFields.reduce((acc,input) => {
          return {...acc,[input.fName]:
              input.fillFromField && data[input.fillFromField]
            ? data[input.fillFromField] : ''};
        },{}));

        navigate('/');

      } else { // doOnSubmit has not succeeded

        setErrMsg(result.msg);

      }

    } else {
      setFieldErr(formFields.reduce((acc,el) => {
        return {...acc,[el.fName]:
          formData[el.fName] || !el.required ? '' : 'Is Required'};
      },{}));
    }

  }

  return(          
            <form className="mainForm" onSubmit={onSubmit}>

              <p className={`errMsg ${errMsg && 'visible'}`}>{errMsg || ''}</p>

              <div className="formContainer">
              {
                formFields.map((input) => { 
                  if (input.field === 'select') {

                    return (
                      <Select className="formInput" key={input.id} data={data}
                        errMsg={fieldErr[input.fName]}
                        selectDesc={input} setFormDataOnChange={setFormDataOnChange}
                      />
                    )

                  } else if (input.type === 'password') {

                    return (
                      <Passwd className="formInput" key={input.id} data={data}
                        errMsg={fieldErr[input.fName]}
                        inputDesc={input} setFormDataOnChange={setFormDataOnChange}
                      />
                    )

                  }
                  return (
                    <Input className="formInput" key={input.id} data={data}
                      errMsg={fieldErr[input.fName]}
                      inputDesc={input} setFormDataOnChange={setFormDataOnChange}
                    />
                  )
                })
              }

              <button>Send</button>
              </div> 
            </form>

  )
}

export default MainForm;