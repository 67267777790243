import './UnderConstruction.scss';

import ConstructionIcon from '@mui/icons-material/Construction';
import EngineeringIcon from '@mui/icons-material/Engineering';
import RoofingIcon from '@mui/icons-material/Roofing';

function UnderConstruction() {

  return (
    <div className="under-construction">

        <div className="icon-container">

            <ConstructionIcon className="icon" />
            <EngineeringIcon className="icon" />
            <RoofingIcon className="icon" />

        </div>

        <hr />
        <h1>Here Will Be Built Magnificent Page</h1>
    </div>
  )
}

export default UnderConstruction