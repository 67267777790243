import './ShowImages.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';

import Gallery from '../../components/gallery/Gallery';
import TagButton from '../../components/gallery/TagButton';

const ShowImages = () => {

  const [ allSearchWords, setAllSearchWords ] = useState([]);
  const [ searchWords, setSearchWords ] = useState([]);
  const [images, setImages] = useState([]);
  const [ imagesToShow, setImagesToShow] = useState([]);

  useEffect(() => {

    const config = {
      headers:{
        "content-type": 'application/json;charset=utf-8'
      }
    };

    
    axios.get(`${window.apiBasePath}/images`, config)
    .then((response) => {
      console.log('response:');
      console.log(response);
      
      if (response.data.length > 0) {
        console.log(response.data);
        setImages(response.data);
        setImagesToShow(response.data);

        const words = {}
        
        response.data.forEach(img => {
          img.keywords.split(',').forEach(word => {
            words[word.trim()] = 1;
          })
        })

        setAllSearchWords(Object.keys(words));

      } else {
        setImages([]);
        setImagesToShow([]);
        setAllSearchWords([]);
      }
      
    }).catch(err => {
      setImages([]);
      setImagesToShow([]);
      console.log(err)
      return
    })

  },[])

  useEffect(() => {

    if (searchWords.length > 0) {

      const filteredImages = images.filter(image => {

        let found = false;
        image.keywords.split(',').forEach(keyword => {
          searchWords.forEach(searchWord => {
            if (keyword.trim() === searchWord) found = true;
          })
        })
        return found;

      })

      setImagesToShow(filteredImages);

    }

  },[searchWords,images])

  const addSearchWord = (word) => {
    console.log(`Add ${word}`)
    setSearchWords(searchWords.concat(word));
  }

  const removeSearchWord = (word) => {
    console.log(`Remove ${word}`)
    setSearchWords(searchWords.filter(searchWord => searchWord !== word));
  }

  return (
    <div className='showImages'>

      <div className="tagButtons">

        {allSearchWords.map((searchWord,i) => 
          <TagButton key={i} tag={searchWord} addSearchWord={addSearchWord}
          removeSearchWord={removeSearchWord} />
        )}

      </div>

      <Gallery images={imagesToShow} />

    </div>
  )
}

export default ShowImages;