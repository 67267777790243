import './new.scss';
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const New = ({inputs, title}) => {

  const navigate = useNavigate();

  const [file, setFile] = useState("");

  const [formData, setFormData] = useState(
    inputs.reduce((acc,input) => {
      return {...acc,[input.label]:''};
    },{})
  );

  const setFormDataOnChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});

  }

  const setFormDataOnFileChange = (e) => {
    
  }

  const onSubmit = (e) => {

    e.preventDefault();
    // we could perform here some formData validation

    console.log(`Form Data:`);
    console.log(formData);

    if (inputs.every(el => formData[el.label])) {
      
      const config = {
        headers:{
          "content-type": 'application/json;charset=utf-8'
        }
      };

      
      axios.post(`${window.apiBasePath}/add${title}`,formData, config)
      .then((response) => {
        console.log('response:');
        console.log(response);
        
        /* if (response.data.auth) {
          console.log(jwtDecode(response.data.accessToken).role);
          setRole(jwtDecode(response.data.accessToken).role);
          setLoggedIn(true);
          
          
          
          sessionStorage.setItem('accessToken',response.data.accessToken);
          
          
        } */
        setFormData({email:'',passwd:''});
        navigate(`/${title}`);
        
      }).catch(err => {
        console.log(err)
        return
      })
      
    }

  }

  return (
    <div className="new">

        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img src={ 
              file ? URL.createObjectURL(file) :
              "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
             } alt="" />
          </div>
          <div className="right">
            <form onSubmit={onSubmit}>
              <div className="formInput file">
                <label htmlFor="file">
                  Image upload: <DriveFolderUploadOutlinedIcon className="icon" />
                  or choose: <SwitchAccountOutlinedIcon className="icon" />
                </label>
                <input type="file" id="file" style={{display:"none"}} 
                       onChange={e => { setFile(e.target.files[0]) }} />
              </div>

              {
                inputs.map((input) => {
                  return (
                    input.field === "input" ?
                        <Input inputDesc={input} setFormDataOnChange={setFormDataOnChange}
                               key={input.id} /> :

                        <Select selectDesc={input} setFormDataOnChange={setFormDataOnChange}
                                key={input.id} />
                     
                  )
                })
              }

              <button>Send</button>
            </form>
          </div>
        </div>
      
    </div>
  )
}

export default New;