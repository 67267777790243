import './GradesOfSingleExam.scss';
import { useState, useEffect, useRef } from 'react';


import { getMissionsByExam } from '../../services/examMissions';
import { getGradesByExam } from '../../services/examGrades';

import LineAround from '../../components/containers/LineAround';
import MyTable from '../../components/datatable/MyTable';

const GradesOfSingleExam = ({aboutExam}) => {

  const [tabColumns, setTabColumns] = useState([]);
  const [data, setData] = useState([]);
  const renderExamRef = useRef(0);

  const tabColumnsInit = [  
    { field: "id", headerName: "rowId", width: 10 },
    { field: "student_name", headerName: "Student Name", width: 60 },
    { field: "assignment_link", headerName: "Assignment Location", width: 60 },
    { field: "answers_link", headerName: "Answers Location", width: 60 },
    { field: "total", headerName: "Total Grade", width: 30, valueFormatter: (params) => params?.value && params.value.toFixed(0) },
    { field: "exam_comment", headerName: "Exam Comment", width: 60 },
    { field: "is_final", headerName: "Final?", width: 40 },
    { field: "who_graded", headerName: "Who Graded", width: 60 }

  ];

  useEffect(() => {

    (async function () {

      let missions = await getMissionsByExam(aboutExam.id);

      const newCols = missions.reduce((acc,m) => {
        acc.push({field: `m${m.mission_id}`, headerName: `${m.mission_id}`, width: 30, valueFormatter: (params) => params?.value && params.value.toFixed(0) })
        acc.push({field: `m${m.mission_id}_comment`, headerName: "comment", width: 90})
        return acc;
      },[...tabColumnsInit])

      setTabColumns(newCols);
      
    })();


  },[])

  useEffect(() => {

    (async function () {
      let gradeData = await getGradesByExam(aboutExam.id);
      setData(gradeData);
      console.log(`In setData for exam ${aboutExam.id}`)
    })();

    renderExamRef.current++

  },[])

  
  function removeGrade(id) {
    console.log(`Wanted to delete ${id}`)
  }


  console.log(`EXAM ${aboutExam.id}, renders: ${renderExamRef.current}, data: \n`,data)



  return (
    <div className="gradesOfSingleExam">
      {/* <h1>exam {aboutExam.id}, renders: {renderExamRef.current}</h1> */}
                <MyTable 
                  what={`Grades of ${aboutExam.assignment_type} 
                     ${aboutExam.title}`}
                  data={data} listOfColumns={tabColumns} 
                  deleteById={removeGrade} />


    </div>
  )
}

export default GradesOfSingleExam