import './LineAround.scss';

const LineAround = ({focus,children,style}) => {
  return (
    <div className={`lineAround ${focus && 'focus'}`} style={style}>
        {children}
    </div>
  )
}

export default LineAround