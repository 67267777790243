export const userInputs = [
    {
      id: 0,
      field: "input",
      label: "Firstname",
      type: "text",
      placeholder: "נועה",
      required: false,
      auto: "on"
    },
    {
      id: 1,
      field: "input",
      label: "Lastname",
      type: "text",
      placeholder: "הררי",
      required: false,
      auto: "on"
    },
    {
      id: 2,
      field: "input",
      label: "Firstname Eng",
      type: "text",
      placeholder: "Noa",
      required: true,
      auto: "on"
    },
    {
      id: 3,
      field: "input",
      label: "Lastname Eng",
      type: "text",
      placeholder: "Harary",
      required: true,
      auto: "on"
    },
    {
      id: 4,
      field: "input",
      label: "Nick",
      type: "text",
      placeholder: "NoaHa",
      required: false,
      auto: "on"
    },
    {
      id: 5,
      field: "select",
      label: "Gender",
      type: "text",
      placeholder: "NoaHa",
      options: [{value:"unknown",text:"Choose"},{value:"female",text:"Female"},
      {value:"male",text:"Male"},{value:"unknown",text:"Not Telling Ya"}],
      required: false,
    },
    {
      id: 6,
      field: "input",
      label: "Birth Date",
      type: "date",
      placeholder: "2000-01-01",
      required: false,
      auto: "on"
    },
    {
      id: 7,
      field: "input",
      label: "Email",
      type: "mail",
      placeholder: "noa_ha@gmail.com",
      required: true,
      auto: "on"
    },
    {
      id: 8,
      field: "input",
      label: "Phone",
      type: "phone",
      placeholder: "+972 53 123-4567",
      required: false,
      auto: "on"
    },
    {
      id: 9,
      field: "input",
      label: "Telegram",
      type: "text",
      placeholder: "",
      required: false,
      auto: "on"
    },
    {
      id: 10,
      field: "input",
      label: "Address",
      type: "text",
      placeholder: "רח' יפו, 201, ירושליים",
      required: false,
      auto: "on"
    },
    {
      id: 11,
      field: "input",
      label: "Password",
      type: "password",
      required: true,
      auto: "current_passwd"
    },
    {
      id: 12,
      field: "input",
      label: "Middle Names",
      type: "text",
      placeholder: "תמר",
      required: false,
      auto: "on"
    },
    {
      id: 13,
      field: "input",
      label: "Middle Names Eng",
      type: "text",
      placeholder: "Tamar",
      required: false,
      auto: "on"
    },
  ];
  
  export const productInputs = [
    {
      id: 1,
      label: "Title",
      type: "text",
      placeholder: "Apple Macbook Pro",
    },
    {
      id: 2,
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
    {
      id: 3,
      label: "Category",
      type: "text",
      placeholder: "Computers",
    },
    {
      id: 4,
      label: "Price",
      type: "text",
      placeholder: "100",
    },
    {
      id: 5,
      label: "Stock",
      type: "text",
      placeholder: "in stock",
    },
  ];

export const loginInputs = [
  {
    id: 1,
    field: "input",
    label: "email",
    fName: "email",
    type: "mail",
    placeholder: "john_doe@gmail.com",
    auto: "on",
    required: true
  },
  {
    id: 2,
    field: "input",
    label: "password",
    fName: "passwd",
    type: "password",
    placeholder: "password",
    auto: "current_passwd",
    required: true
  },
];

export const firstLoginEmailVerificationInputs = [
  {
    id: 0,
    field: "input",
    label: "Fix Email if Needed and Verify",
    fName: "email",
    type: "mail",
    fillFromField: "email",
    placeholder: "john_doe@gmail.com",
    auto: "on",
    required: true
  },
];

export const firstLoginInputs = [
  {
    id: 0,
    field: "input",
    label: "Fix Firstname if Needed",
    fName: "firstname",
    type: "text",
    placeholder: "נועה",
    fillFromField: "firstname",
    required: true,
    auto: "on"
  },
  {
    id: 1,
    field: "input",
    label: "Lastname",
    fName: "lastname",
    type: "text",
    placeholder: "הררי",
    fillFromField: "lastname",
    required: true,
    auto: "on"
  },
  {
    id: 2,
    field: "input",
    label: "Firstname Eng",
    fName: "firstname_en",
    type: "text",
    placeholder: "Noa",
    fillFromField: "firstname_en",
    required: true,
    auto: "on"
  },
  {
    id: 3,
    field: "input",
    label: "Lastname Eng",
    fName: "lastname_en",
    type: "text",
    placeholder: "Harary",
    fillFromField: "lastname_en",
    required: true,
    auto: "on"
  },
  {
    id: 4,
    field: "input",
    label: "Nick",
    fName: "nick",
    type: "text",
    placeholder: "NoaHa",
    fillFromField: "nick",
    required: true,
    auto: "on"
  },
  {
    id: 5,
    field: "select",
    label: "Gender",
    fName: "gender",
    type: "text",
    placeholder: "NoaHa",
    fillFromField: "gender",
    options: [{value:"unknown",text:"Choose"},{value:"female",text:"Female"},
    {value:"male",text:"Male"},{value:"unknown",text:"Not Telling Ya"}],
    required: false,
  },
  {
    id: 6,
    field: "input",
    label: "email",
    fName: "email",
    type: "mail",
    placeholder: "john_doe@gmail.com",
    fillFromField: "email",
    auto: "on",
    required: true
  },
  {
    id: 7,
    field: "input",
    label: "password",
    fName: "passwd",
    type: "password",
    placeholder: "password",
    fillFromField: "passwd",
    auto: "current_passwd",
    required: true
  }
];

export const enterCourseInputs = [
  {
    id: 0,
    field: "select",
    label: "course",
    fName: "course",
    type: "select",
    placeholder: "",
    options: [{value:"",text:"-- Choose from the List --"}],
    getMoreOptions: ["getCourseList","courses.js","id","title"],
    required: true,
    dataType: "number"
  },
  {
    id: 1,
    field: "input",
    label: "coursepasswd",
    fName: "coursepasswd",
    type: "password",
    placeholder: "password",
    auto: "current_passwd",
    required: true
  },
]