import './ListsOfStudentGrades.scss';
import {jwtDecode} from 'jwt-decode';
import { useEffect,useContext } from 'react';

import { userContext } from "../../../context/userContext";
import { useAuthStore } from "../../../context/authStore";
import { useStudentStore } from "../../../context/studentStore";
import { useShallow } from "zustand/react/shallow";

import { getCourseByStudent } from '../../../services/courses';
import { getExamsByStudent } from '../../../services/exams';
import { getGradesByStudent } from '../../../services/examGrades';

import MyTable from '../../../components/datatable/MyTable';

const ListsOfStudentGrades = () => {
  
  const {dispatchUser} = useContext(userContext);

  const { studentDetails, setStudentDetails, examList, setExamList,
    course, setCourse, gradeList, setGradeList, 
    emptyStore:emptyStudentStore } = useStudentStore(
      useShallow((state) => ({ studentDetails: state.studentDetails, 
                      setStudentDetails: state.setStudentDetails,
                          examList: state.examList,
                          setExamList: state.setExamList,
                          course: state.course,
                          setCourse: state.setCourse, 
                          gradeList: state.gradeList,
                          setGradeList: state.setGradeList,
                          emptyStore: state.emptyStore
                        }))
  );

  const { accessToken, emptyStore: emptyAuthStore } = useAuthStore(
      useShallow((state) => ({ accessToken: state.accessToken,
                         emptyStore: state.emptyStore  }))
  );

const controller = new AbortController();

useEffect(() => {

  let isMounted = true;

  async function fillStoreIfEmpty() {

    let token;
    if (Object.keys(studentDetails).length === 0) { // if student is an empty obj

      //token = jwtDecode(sessionStorage.getItem('accessToken'));
      token = jwtDecode(accessToken);
      setStudentDetails(token);
    }

    if (token) {

      if (Object.keys(course).length === 0) {
        await getCourseByStudent(token.id, setCourse,
          controller, accessToken, dispatchUser,token.role,emptyAuthStore,
          emptyStudentStore
          );
      }
        
      isMounted && await getExamsByStudent(token.id, examList, setExamList,
          controller, accessToken);
      isMounted && await getGradesByStudent(token.id, gradeList, setGradeList,
            controller, accessToken);
            
    }
  }

  if (accessToken) fillStoreIfEmpty();

  return () => {
    
    isMounted = false;
    //controller.abort();
  }

}, [accessToken])
/** id, exam_id, mission_id, description, max_points, grade, comment */
let tabColumns = [
  {field: `id`, headerName: `Id`, width: 30},
  {field: `exam_id`, headerName: `Exam Id`, width: 30},
  {field: `mission_id`, headerName: `Mission Id`, width: 100},
  {field: `description`, headerName: `Description`, width: 150},
  {field: `max_points`, headerName: `Max Points`, width: 100},
  {field: `grade`, headerName: `Grade`, width: 70},
  {field: `comment`, headerName: `Comment`, width: 150},
]

let columnsVisible = {
  id: false,
  exam_id: false
}

function removeGrade(id) {
  console.log(`Wanted to delete ${id}`)
}

/* console.log(`studentDetails: \n`,studentDetails)
console.log(`course: \n`,course)
console.log(`examList: \n`,examList)
console.log(`gradeList: \n`,gradeList) */

  return (
    <div className="listsOfStudentGrades"> 
      <div className="title">
          <span>Exam and Assignment Grades 
            of {studentDetails.firstname_en} in the course
            {course.title}
          </span>
      </div>
      <div className="showStudentGradesByExam">

          {
            examList.list.length === 0 ? 
            <div className="title">No Exams and Assignments Yet</div> : 
            examList.list.map(e => {
              // console.log(`exam:\n`,e)

              return <div className="gradesOfSingleStudentExam" 
                        key={`singleStudExam_${e.id}_${studentDetails.id}`}>
              
                        <MyTable 
                          what={`Grades of ${e.assignment_type} 
                             ${e.title}`}
                          data={gradeList.list.filter(g=>g.exam_id === e.id)} 
                          listOfColumns={tabColumns} 
                          noActions={true}
                          deleteById={removeGrade}
                          columnsVisible={columnsVisible}
                          needsCheckboxes={false}
                        />
        
        
            </div>
              /* return <GradesOfSingleStudentExam key={`whole_exam_${e.id}`} /> */
            })
          }
          
      </div>
    </div>
  )
}

export default ListsOfStudentGrades