import './GradeMissions.scss';
import { useState, useEffect, useRef } from 'react';
import { useCourseListStore } from "../../context/courseListStore";
import { useAuthStore } from "../../context/authStore";
import { useExamStore } from "../../context/examStore";

import { useShallow } from "zustand/react/shallow";

import { getExamsByCourse } from '../../services/exams';
import { getGradesByExamAndStudent, getMissionsToGradeByExam, getGradesByStudentNotCurrUser, 
  addGrades, updateGrades, deleteGrade } from '../../services/examGrades';

import { gradeMissionColumns } from '../../dataStructure/gradeMissionColumns';

import LineAround from '../../components/containers/LineAround';
import EdiTable from '../../components/datatable/EdiTable';
import Select from '../../components/form/Select';

const GradeMissions = () => {

        
  const [currStudent,setCurrStudent] = useState(null);
  const [gradesData,setGradesData] = useState(null);

  const { examList, setExamList,
    course, studentList, setStudentList } = useCourseListStore(
        useShallow((state) => ({ 
            examList: state.examList,
            setExamList: state.setExamList,
            course: state.course,
            studentList: state.studentList,
            setStudentList: state.setStudentList,

    }))
  );
  const { exam, setExam, resetExam,
    missions, setMissions, updateStoredMissions,
    addMissionsToStore, removeMissionById } = useExamStore(
        useShallow((state) => ({ 
            exam: state.exam,
            setExam: state.setExam,
            resetExam: state.resetExam,
            missions: state.missions,
            setMissions: state.setMissions,
            updateStoredMissions: state.updateMissions,
            addMissionsToStore: state.addMissions,
            removeMissionById: state.removeMissionById
    }))
  );

  const { accessToken } = useAuthStore(
    useShallow((state) => ({ accessToken: state.accessToken
                        }))
  );


  const gradeMissionsAmtRenders = useRef(0);
  gradeMissionsAmtRenders.current++;
  console.log(`gradeMissionsAmtRenders = ${gradeMissionsAmtRenders.current}`)
            
                     
  const [ focus, setFocus ] = useState(Object.keys(course).length === 0
                                         ? 'course' : 'exam');

  useEffect(() => {

    console.log(`Course has changed to ${course.id}`)

    async function fillExamsIfThereIsCourse() {

      if (Object.keys(course).length !== 0) { // if course is not an empty obj
        
        await getExamsByCourse(course.id, examList, setExamList);
        
      }
    }

    fillExamsIfThereIsCourse();

  }, [course])

  useEffect(() => {

    console.log('IN USE EFFECT EFFECT EFFECT')
    const getGradesBy = async () => {

      let examGrades;
      if (exam?.id && currStudent?.id) 
        examGrades = await getGradesByExamAndStudent(exam.id,currStudent.id,
                                              accessToken)
      else if (exam?.id)
        examGrades = await getMissionsToGradeByExam(exam.id,accessToken)
      else if (currStudent?.id)
        examGrades = await getGradesByStudentNotCurrUser(currStudent.id,accessToken)
      /* else
        examGrades = await getGradesByCourse(course.id,accessToken) */
      setGradesData(examGrades);  
    }

    getGradesBy();

  },[exam, currStudent])

  const selectExamDesc = {
    id: 0,
    field: "select",
    label: "Choose Exam to Grade:",
    fName: "exam",
    type: "select",
    placeholder: "",
    options: [{value:"",text:"-- Choose from the List --"}],
    getMoreOptions: ["getExamsByCourse","exams.js","id","title",[course.id,examList,setExamList]],
    required: true,
    dataType: "number"
  }

  const selectStudentDesc = {
    id: 0,
    field: "select",
    label: "Choose Student to Grade:",
    fName: "student",
    type: "select",
    placeholder: "",
    options: [{value:"",text:"-- Choose from the List --"}],
    getMoreOptions: ["getCourseStudents","courses.js","id","name",[course.id,setStudentList]],
    required: true,
    dataType: "number"
  }



  const handleExamChange = async (e) => {
    let val = +e.target.value;
    if (val === exam?.id) return;
    if (val === '') {
      resetExam();
      setFocus('exam')
    }
    else {
      setExam(examList.list.filter(e => e.id === val)[0]);
    }
    
  }

 

  const handleStudentChange = async (e) => {
    console.log(`THE STUDENT IS CHANGING`)
    console.log(`studentList: \n`, studentList)
    let val = e.target.value;
    console.log(`val = ${val}`)
    //if (val === currStudent?.id) return;
    if (val === '') setCurrStudent({})
    else {
      console.log(`THIS IS THE STUDENT \n`,studentList.filter(s => s.id === val)[0])
      setCurrStudent(studentList.filter(s => s.id === val)[0]);

    }
    
  }

  const handleUpdateGrades = async (updatedGradeData) => {

    let result = await updateGrades(updatedGradeData, accessToken);

    if (result.success)  
        setGradesData(gradesData.map(g => {
      
           let updatedRow = updatedGradeData.find(u => u.id === g.id);
           return updatedRow ? updatedRow : g; 

        }));

    return result;

  }

  const handleAddGrades = async (newGradesData) => {

    let result = await addGrades(newGradesData, accessToken);

    if (result.success) 
       setGradesData(gradesData.concat(newGradesData));

    return result;

  }

  const handleDeleteGrade = async (id) => {

    let result = await deleteGrade(id, accessToken);

    if (result.success) 
      setGradesData(gradesData.filter(g => g.id !== id));

    return result;

  }

  return (
    <div className="gradeMissions">

        <div className="left">

        <div className="selects">


        <LineAround focus={focus === 'exam'}>

            <Select className={`formInput`}  
                selectDesc={selectExamDesc} width="100%"
                setFormDataOnChange={handleExamChange}
                />
        
        </LineAround>

        <LineAround focus={focus === 'exam'}>

            <Select className={`formInput`}  
                selectDesc={selectStudentDesc} width="100%"
                setFormDataOnChange={handleStudentChange}
                />
        
        </LineAround>

        </div>

        <LineAround focus={focus !== 'course' } 
                    style={{backgroundColor:"rgba(0,128,129,0.5)"}}>
            {
                focus !== 'course' &&
                <EdiTable 
                  what={`Missions of ${exam.assignment_type} ${exam.title}, id: ${exam.id}`}
                  data={gradesData} listOfColumns={gradeMissionColumns} 
                  deleteById={handleDeleteGrade} addRows={handleAddGrades} 
                  updateRows={handleUpdateGrades} editable={true}
                  outerHeight={'2000px'} tableForId='exam_and_assignment_grades' />
            }
        </LineAround>

        </div>



    </div>
  )
}

export default GradeMissions