import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

const config = {
    headers:{
      "content-type": 'application/json;charset=utf-8',
      "Access-Control-Allow-Private-Network": true
    }
};

const getAuthConfig = (accessToken) => {
    // return authorization header with jwt token
    //let accessToken = sessionStorage.getItem('accessToken');
    
    if (accessToken) {
        return { ...config, headers: {...config.headers,
                                         authorization: `Bearer ${accessToken}` }}
    } else {
        return config;
    };
    
}

export const login = async (formData, dispatchUser, setAccessToken) => {

    try {

        let response = await axios.post(`${window.apiBasePath}/login`,formData, config)

        if (response.data.auth) {
            
            dispatchUser({type:jwtDecode(response.data.accessToken).role.toUpperCase()});
            
            setAccessToken(response.data.accessToken);
            sessionStorage.setItem('accessToken',response.data.accessToken);

            return({status:true})
        } else {
            dispatchUser({type:"LOGOUT"});

            sessionStorage.removeItem('accessToken');

            console.log(`Auth failed`);
            return({status:false,msg: "Auth failed"})
        }

    } catch(err)  {
        
        if (err.response) {
            console.log('err.response')
            // The client was given an error response (5xx, 4xx)
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
            if (err.response.status>=500)
                return({status:false,msg: `ERR ${err.response.status}: ${err.response.statusText}`})
            else
                return({status:false,msg: `ERR ${err.response.status}: ${err.response.data.message}`})
        } else if (err.request) {
            console.log('err.request')
            // The client never received a response, and the request was never left
            return({status:false,msg: "Server has not responded"})
        } else {
            console.log('other err')
            return({status:false,msg: "Server other err"})

        }
    }

}

export const forgotPasswd = async (email) => {

    try {

        let response = await axios.post(`${window.apiBasePath}/auth/forgot-passwd`,{email}, config)
        
        return response.data;

    } catch(err)  {
        
        if (err.response) {
            console.log('err.response')
            // The client was given an error response (5xx, 4xx)
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
        } else if (err.request) {
            console.log('err.request')
            // The client never received a response, and the request was never left
        } else {
            console.log('other err')
        }

        return {err: err.message, success:false}
    }

}

export const resetPasswd = async (formData) => {

    console.log(`login: \n`,formData)
    try {

        let response = await axios.post(`${window.apiBasePath}/auth/reset-passwd`,formData, config)
        
        return response.data;

    } catch(err)  {
        console.log(err)
        if (err.response) {
            console.log('err.response')
            // The client was given an error response (5xx, 4xx)
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
        } else if (err.request) {
            console.log('err.request')
            // The client never received a response, and the request was never left
        } else {
            console.log('other err')
        }

        return {err: err.message, status:false}
    }

}

export const sendCode = async (email, code) => {

    try {

        let response = await axios.post(`${window.apiBasePath}/send-code`,{email,code}, config)

        return response.data;

    } catch(err)  {
        console.log(err)
        
        return {status: 'ERROR',msg:'The server is under maintenance. Please try later'}
    }

}

export const getRole = () => {

        let accessToken = sessionStorage.getItem('accessToken');
        let role = accessToken ? jwtDecode(accessToken).role : 'guest';
        
        return role;

}

export const logout = async (dispatchUser,role,emptyAuthStore,emptyStudentStore,
                             accessToken) => {

    try {

        await axios.post(`${window.apiBasePath}/logout`,{}, 
                                        getAuthConfig(accessToken));
        
    } catch(err)  {
        
        if (err.response) {
            console.log('err.response')
            // The client was given an error response (5xx, 4xx)
            //console.log(err.response.data);
            //console.log(err.response.status);
            //console.log(err.response.headers);
        } else if (err.request) {
            //console.log('err.request')
            // The client never received a response, and the request was never left
        } else {
            //console.log('other err')

        }
    }

    if (role === 'student') emptyStudentStore();
    emptyAuthStore();
    dispatchUser({type:"LOGOUT"});
    sessionStorage.removeItem('accessToken');

}