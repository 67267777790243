import './FirstLogin.scss';
import Code from '../../components/form/Code';
import Input from '../../components/form/Input';
import MainForm from '../../components/form/MainForm';

import { useState, useContext, useRef } from 'react';
import { userContext } from '../../context/userContext';

import { useNavigate, useLocation } from 'react-router-dom';
import { login, sendCode } from '../../services/auth';
import { firstLoginInputs, firstLoginEmailVerificationInputs } from '../../formSource';

import { useAuthStore } from "../../context/authStore";
import { useShallow } from "zustand/react/shallow";



const FirstLogin = () => {

  const { accessToken, setAccessToken } = useAuthStore(
    useShallow((state) => ({ accessToken: state.accessToken, 
                    setAccessToken: state.setAccessToken,
                        }))
);

  const { state } = useLocation();
  const [user,setUser] = useState(state.user);

  const [emailVerified,setEmailVerified] = useState(
            sessionStorage.getItem('emailVerified') 
              ? JSON.parse(sessionStorage.getItem('emailVerified')) 
              : {status:"not verified",email:user.email});
  sessionStorage.setItem('emailVerified',JSON.stringify(emailVerified));
  let code = useRef(undefined);
  const [emailError,setEmailError] = useState('');
  const [codeErrMsg,setCodeErrMsg] = useState('');

  const navigate = useNavigate();
  const { dispatchUser } = useContext(userContext);

  const [formData, setFormData] = useState(
    firstLoginInputs.reduce((acc,input) => {
      return {...acc,[input.label]:''};
    },{})
  );
  
  const setFormDataOnChange = (e) => {

    setFormData({...formData, [e.target.name]: e.target.value});

  }
  const setEmailDataOnChange = (e) => {

    setUser({...user, email: e.target.value});
    setEmailVerified({status:"not verified",email: e.target.value});
    localStorage.setItem('emailVerified',
           JSON.stringify({status:"not verified",email: e.target.value}))

  }

  const checkCode = (inputCode) => {

    if (inputCode === code.current) {

      const newEmailVerified = {...emailVerified,status:"verified"};
      setEmailVerified(newEmailVerified);
      sessionStorage.setItem('emailVerified',JSON.stringify(newEmailVerified));
      setCodeErrMsg("");

    } else {

      setCodeErrMsg("Invalid code");

    }

  }

  const onSubmit = async (formData) => {
     
      return await login({...formData,isFirstLogin: true}, dispatchUser,setAccessToken);
          
  }

  const verifyEmail = async (e) => {
    
    e.preventDefault();
    // we could perform here some formData validation
    
    code.current = Math.floor(Math.random() * 89999) + 10000; 
    // five digits, not starting with 0
    
    let response = await sendCode(user.email, code.current);

    if (response.status === 'SUCCESS') {

      setEmailError('');
      const newEmailVerified = {...emailVerified,status:"code sent"}
      setEmailVerified(newEmailVerified);
      sessionStorage.setItem('emailVerified',JSON.stringify(newEmailVerified));

    } else {

      setEmailError(response.msg);

    }
    
  }

  const goBackToNotVerifiedEmail = () => {

    const newEmailVerified = {...emailVerified,status:"not verified"}
      setEmailVerified(newEmailVerified);
      sessionStorage.setItem('emailVerified',JSON.stringify(newEmailVerified));

  }

  return (
    <div className="first-login">
        <div className="top">
          <h1>First Login by {user.firstname_en+" "} 
          {user.lastname_en} as a {user.role} of the course {user.course_title} </h1>
        </div>
        <div className="bottom">

          <div className="right">
            {emailVerified.status === "not verified" ?

              <form onSubmit={verifyEmail}>

              {
                firstLoginEmailVerificationInputs.map((input) => { 
                  return (
                    <Input className="formInput" key={input.id} data={user}
                      inputDesc={input} setFormDataOnChange={setEmailDataOnChange}
                      errMsg={emailError}
                    />
                  )
                })
              }

              <button>Verify Email</button>
              </form>
            : emailVerified.status === "code sent" ?
            <>

              <h3>The mail with the code was sent to {user.email}</h3>
              <h3>Please, check your email and fill in the verification code</h3>
              <Code amtOfDigits={5} checkCode={checkCode} errMsg={codeErrMsg} />

              <button onClick={goBackToNotVerifiedEmail}>Back</button>
              <button onClick={verifyEmail}>Send Again</button>

            </>
            :
            <MainForm formFields={firstLoginInputs} data={user} doOnSubmit={onSubmit} />
            // <form onSubmit={onSubmit}>

            //   {
            //     firstLoginInputs.map((input) => { 
            //       return (
            //         <Input className="formInput" key={input.id} data={user}
            //           inputDesc={input} setFormDataOnChange={setFormDataOnChange}
            //         />
            //       )
            //     })
            //   }

            //   <button>Send</button>
            // </form>
          } {/* emailVerified is "not verified" or "code sent" or "verified" */}
            
          </div>
        </div>
    </div>
  )
}

export default FirstLogin;