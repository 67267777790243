const schoolColumns = [
  { field: "id", headerName: "ID", width: 70 },

  {
    field: "schoolName",
    headerName: "School Name",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={require(`./assets/img/${params.row.school_logo}`)} alt={`${params.row.school_logo_alt} logo`} />
          {params.row.school_name}
        </div>
      );
    },
  },

  {
    field: "schoolSite",
    headerName: "School Site",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <a className="cellImg" href={params.row.school_site}>{params.row.school_site}</a>
        </div>
      );
    },
  }

];

const userColumns = [
  { field: "id", headerName: "ID", width: 40 },
  {
    field: "fullName",
    headerName: "Full Name",
    width: 130,
    renderCell: (params) => {
      return (
        <div>
          {`${params.row.firstname}${params.row.middle_names ? ' '+params.row.middle_names : ''} ${params.row.lastname}`}
        </div>
      );
    },
  },
  {
    field: "user",
    headerName: "User",
    width: 130,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.avatar} alt={params.row.avatar_alt} />
          {`${params.row.nick}`}
        </div>
      );
    },
  },
  {
    field: "fullEngName",
    headerName: "Full Eng Name",
    width: 130,
    renderCell: (params) => {
      return (
        <div>
          {`${params.row.firstname_en}${params.row.middle_names_en ? ' '+params.row.middle_names_en : ''} ${params.row.lastname_en}`}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "birth_date",
    headerName: "Birth Date",
    width: 100,
  },
  {
    field: "gender",
    headerName: "Gender",
    width: 90,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.gender}`}>
          {params.row.gender}
        </div>
      );
    },
  },
  {
    field: "role",
    headerName: "Role",
    width: 90,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.role}`}>
          {params.row.role}
        </div>
      );
    },
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 100,
  },
  {
    field: "telegram",
    headerName: "Telegram",
    width: 100,
  },
  {
    field: "user_address",
    headerName: "Address",
    width: 100,
  },
  {
    field: "create_date",
    headerName: "Create Date",
    width: 100,
  },
  {
    field: "update_date",
    headerName: "Update Date",
    width: 100,
  },
  {
    field: "who_updated",
    headerName: "Who Updated",
    width: 100,
  },
];

const studentColumns = [
  { field: "user_id", headerName: "USER ID", width: 40 },
  {
    field: "schoolAndCourse",
    headerName: "School and Course",
    width: 130,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={require(`./assets/img/${params.row.school_logo}`)} 
                                   alt={params.row.school_logo_alt} />
          {`${params.row.short_title}`}
        </div>
      );
    },
  },
  {
    field: "fullName",
    headerName: "Full Name",
    width: 130,
    renderCell: (params) => {
      return (
        <div>
          {`${params.row.firstname}${params.row.middle_names ? ' '+params.row.middle_names : ''} ${params.row.lastname}`}
        </div>
      );
    },
  },
  {
    field: "hwDir",
    headerName: "HW Dir",
    width: 130,
    renderCell: (params) => {
      return (
        <div>
          {`${params.row.not_null_subdomain}${params.row.directory}`}
        </div>
      );
    },
  },
  {
    field: "portfolio",
    headerName: "Portfolio",
    width: 130,
    renderCell: (params) => {
      return (
        <div>
          {`${params.row.portfolio}`}
        </div>
      );
    },
  },
  {
    field: "user",
    headerName: "User",
    width: 130,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.avatar} alt={params.row.avatar_alt} />
          {`${params.row.nick}`}
        </div>
      );
    },
  },
  {
    field: "fullEngName",
    headerName: "Full Eng Name",
    width: 130,
    renderCell: (params) => {
      return (
        <div>
          {`${params.row.firstname_en}${params.row.middle_names_en ? ' '+params.row.middle_names_en : ''} ${params.row.lastname_en}`}
        </div>
      );
    },
  },
  {
    field: "first_login_date",
    headerName: "First Login Date",
    width: 50,
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "birth_date",
    headerName: "Birth Date",
    width: 100,
  },
  {
    field: "gender",
    headerName: "Gender",
    width: 90,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.gender}`}>
          {params.row.gender}
        </div>
      );
    },
  },
  {
    field: "role",
    headerName: "Role",
    width: 90,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.role}`}>
          {params.row.role}
        </div>
      );
    },
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 100,
  },
  {
    field: "telegram",
    headerName: "Telegram",
    width: 100,
  },
  {
    field: "user_address",
    headerName: "Address",
    width: 100,
  },
  {
    field: "when_created",
    headerName: "Create Date",
    width: 100,
  },
  {
    field: "when_updated",
    headerName: "Update Date",
    width: 100,
  },
  {
    field: "who_updated",
    headerName: "Who Updated",
    width: 100,
  },
];

const examColumns = [
  { field: "id", headerName: "ID", width: 30 },
  { field: "title", headerName: "Title", width: 120 },
  { field: "assignment_type", headerName: "What", width: 120 },
  { field: "themes", headerName: "Themes", width: 120 },
  { field: "short_desc", headerName: "Short Description", width: 120 },
  { field: "description", headerName: "Description", width: 120 },
  { field: "is_finished", headerName: "If Finished", width: 120 },
  { field: "weight_percents", headerName: "Weight", width: 120,
    renderCell: (params) => {
      return (
        <div>{params.row.weight_percents}{params.row.weight_percents && '%'}</div>
      );
    },
  },
  { field: "length_hours", headerName: "Length, hours", width: 120,
    renderCell: (params) => {
      return (
        <div>{params.row.length_hours}</div>
      );
    },
  },
  { field: "required", headerName: "If Required", width: 120 },
  {
    field: "who_created",
    headerName: "Who Created Exam",
    width: 100,
  },
  {
    field: "who_is_grading",
    headerName: "Who Is Grading Exam",
    width: 100,
  },
  {
    field: "link_to_assignment",
    headerName: "Link to Assignment",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <a className="cellImg" href={params.row.link_to_assignment}>{params.row.link_to_assignment}</a>
        </div>
      );
    },
  },
  {
    field: "link_to_student_answers",
    headerName: "Link to Student Answers",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <a className="cellImg" href={params.row.link_to_student_answers}>{params.row.link_to_student_answers}</a>
        </div>
      );
    },
  },
  {
    field: "when_created",
    headerName: "Create Date",
    width: 100,
  },
  {
    field: "when_updated",
    headerName: "Update Date",
    width: 100,
  },
  {
    field: "who_updated",
    headerName: "Who Updated",
    width: 100,
  },
];

const missionColumns = [
  { field: "id", headerName: "ID", width: 30 },
  { field: "exam_id", headerName: "Exam ID", width: 30 },
  { field: "mission_id", headerName: "Mission ID", width: 30 },
  { field: "mission_type", headerName: "Mission Type", width: 90 },
  { field: "title", headerName: "Title", width: 120 },
  { field: "max_points", headerName: "Max Points", width: 30 },
  { field: "weight_percents", headerName: "Weight", width: 120,
    renderCell: (params) => {
      return (
        <div>{params.row.weight_percents}{params.row.weight_percents && '%'}</div>
      );
    },
  },
  { field: "themes", headerName: "Themes", width: 120 },
  { field: "description", headerName: "Description", width: 120 },
  { field: "question_type", headerName: "Question Type", width: 100 },
  { field: "right_answer", headerName: "Right Answer", width: 120 },
  { field: "length_minutes", headerName: "Length, minutes", width: 30,
    renderCell: (params) => {
      return (
        <div>{params.row.length_hours}</div>
      );
    },
  },
  { field: "required", headerName: "If Required", width: 120 },
  {
    field: "link_to_mission",
    headerName: "Link to Mission",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <a className="cellImg" href={params.row.link_to_mission}>{params.row.link_to_mission}</a>
        </div>
      );
    },
  },
  {
    field: "who_created",
    headerName: "Who Created Mission",
    width: 100,
  },
  {
    field: "who_is_grading",
    headerName: "Who Is Grading Mission",
    width: 100,
  },
  {
    field: "when_created",
    headerName: "Create Date",
    width: 100,
  },
  {
    field: "when_updated",
    headerName: "Update Date",
    width: 100,
  },
  {
    field: "who_updated",
    headerName: "Who Updated",
    width: 100,
  },
];

export const columns = {
  exams: examColumns,
  missions: missionColumns,
  schools: schoolColumns,
  users: userColumns,
  students: studentColumns
}

//temporary data
export const userRows = [
  {
    id: 1,
    username: "Snow",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: "active",
    email: "1snow@gmail.com",
    age: 35,
  },
  {
    id: 2,
    username: "Jamie Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "2snow@gmail.com",
    status: "passive",
    age: 42,
  },
  {
    id: 3,
    username: "Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "3snow@gmail.com",
    status: "pending",
    age: 45,
  },
  {
    id: 4,
    username: "Stark",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "4snow@gmail.com",
    status: "active",
    age: 16,
  },
  {
    id: 5,
    username: "Targaryen",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "5snow@gmail.com",
    status: "passive",
    age: 22,
  },
  {
    id: 6,
    username: "Melisandre",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "6snow@gmail.com",
    status: "active",
    age: 15,
  },
  {
    id: 7,
    username: "Clifford",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "7snow@gmail.com",
    status: "passive",
    age: 44,
  },
  {
    id: 8,
    username: "Frances",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "8snow@gmail.com",
    status: "active",
    age: 36,
  },
  {
    id: 9,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "pending",
    age: 65,
  },
  {
    id: 10,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    age: 65,
  },
];