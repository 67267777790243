import './InsertExamQuestions.scss';
import { useState, useEffect } from 'react';
import { useCourseListStore } from "../../context/courseListStore";
import { useExamStore } from "../../context/examStore";
import { useShallow } from "zustand/react/shallow";

import { getMissionsByExam } from '../../services/examMissions';
import { getExamFileParsed, getExamsByCourse } from '../../services/exams';
import { examMissionColumns } from '../../dataStructure/examMissionColumns';

import InputFile from '../../components/form/InputFile';
import LineAround from '../../components/containers/LineAround';
import MyTable from '../../components/datatable/MyTable';
import Select from '../../components/form/Select';

import axios from 'axios';

const InsertExamQuestions = () => {

  const [ examFile, setExamFile ] = useState(null);
  const [ examFileData, setExamFileData ] = useState([]);
        
  const { courseList, setCourseList, examList, setExamList,
    course, setCourse } = useCourseListStore(
        useShallow((state) => ({ courseList: state.courseList, 
            setCourseList: state.setCourseList,
            examList: state.examList,
            setExamList: state.setExamList,
            course: state.course,
            setCourse: state.setCourse,
    }))
  );
  console.log(`IN INSERT-EXAM-QUESTIONS, setExamList:\n`,setExamList)
            
  const { exam, setExam, missions, setMissions, removeMissions } = useExamStore(
    useShallow((state) => ({ exam: state.exam, 
        setExam: state.setExam,
        missions: state.missions,
        setMissions: state.setMissions,
        removeMissions: state.removeMissions }))
  );  
                    
  const [ focus, setFocus ] = useState(Object.keys(course).length === 0
                                         ? 'course' : 'exam');

  useEffect(() => {

    async function fillExamsIfThereIsCourse() {

      if (Object.keys(course).length !== 0) { // if course is not an empty obj
        console.log(`In fillExamsIfThereIsCourse: examList: \n`,examList)
        await getExamsByCourse(course.id, examList, setExamList);
        
      }
    }

    fillExamsIfThereIsCourse();

  }, [course])

  const selectCourseDesc = {
    id: 0,
    field: "select",
    label: "Choose the Course and After That - the Exam",
    fName: "course",
    type: "select",
    placeholder: "",
    fillFromField: "id",
    options: [{value:"",text:"-- Choose from the List --"}],
    getMoreOptions: ["getCourseList","courses.js","id","title",[courseList,setCourseList]],
    required: true,
    dataType: "number"
  }

  const selectExamDesc = {
    id: 0,
    field: "select",
    label: "Choose Exam to Add Questions to It:",
    fName: "exam",
    type: "select",
    placeholder: "",
    options: [{value:"",text:"-- Choose from the List --"}],
    getMoreOptions: ["getExamsByCourse","exams.js","id","title",[course.id,examList,setExamList]],
    required: true,
    dataType: "number"
  }

  const inputFileDesc = {
    id: 0,
    field: "input",
    label: "Upload Exam to Parse It's Questions:",
    fName: "examFile",
    type: "file",
    placeholder: "",
    required: true,
    dataType: "file"
  }

  const handleCourseChange = (e) => {
    console.log(`In handleCourseChange`)
    let val = +e.target.value;
    if (val === course.id) return;
    if (val === '') setFocus('course')
    else setFocus('exam');
    setCourse(courseList.list.filter(c => c.id === val)[0]);
  }

  const handleExamChange = async (e) => {
    let val = +e.target.value;
    if (val === exam.id) return;
    if (val === '') setFocus('exam')
    else {
        const examMissions = await getMissionsByExam(val);
        
        setMissions(examMissions);
        setFocus('examFile');
    }
    
    setExam(examList.list.filter(e => e.id === val)[0]);
  }

  const handleFileChange = async (e) => {
    console.log(`e.target.files[0]\n`,e.target.files[0])
    setExamFile(e.target.files[0]);

    const formData = new FormData();
    
    formData.append("examFile",e.target.files[0]);

    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    }

    try {
        let response = await axios.post(`${window.apiBasePath}/exams/parse-file`,formData,config);
        
        setExamFileData(response.data.missions);
        
    } catch (err) {

        console.log(err);
        
    }

    // const formData = new FormData();

    //     formData.append(
    //         "examFile",
    //         e.target.files[0],
    //         e.target.files[0].name
    //     );
    // const result = await getExamFileParsed(formData);
    // console.log(result)
    // setExamFileData(result);
     
  }

  const showFile = async () => {

    const formData = new FormData();
    
    formData.append("examFile",examFile);
    formData.append("examFileName",examFile.name);

    const result = await getExamFileParsed(formData);
    console.log(result)
    setExamFileData(result);

  }

  console.log(`Before RETURN, examList:\n`,examList)
  return (
    <div className="insertExamQuestions">

        <div className="left">

        <LineAround focus={focus === 'course'}>

            <Select className="formInput" selectDesc={selectCourseDesc} 
                setFormDataOnChange={handleCourseChange} width="100%"
                data={{id: course.id}}
            />

        </LineAround>

        <LineAround focus={focus === 'exam'}>

            <Select className={`formInput`} isDisabled={focus === 'course'} 
                selectDesc={selectExamDesc} width="100%"
                setFormDataOnChange={handleExamChange}
                />
        
        </LineAround>

        <LineAround focus={focus !== 'course' && focus !== 'exam'} 
                    style={{maxWidth:"600px"}}>
            {
                focus !== 'course' && focus !== 'exam' &&
                <MyTable 
                  what={`Missions of ${exam.assignment_type} ${exam.title}`}
                  data={missions} listOfColumns={examMissionColumns} 
                  deleteById={removeMissions} />
            }
        </LineAround>

        </div>

        <div className="right">

        <LineAround focus={focus !== 'course' && focus !== 'exam'}>

            <InputFile className={`formInput`} isDisabled={focus === 'course' || focus === 'exam'} 
                inputFileDesc={inputFileDesc} width="100%"
                setFormDataOnChange={handleFileChange}
                />

        </LineAround>

        <button onClick={showFile} >Show File</button>

        <LineAround focus={focus !== 'course' && focus !== 'exam'}
           className="parsedExam" >

            {examFileData.map((e,ix) => {
                return <div key={ix}>
                    <p>***************************</p>
                    <p>{e.txt}</p>
                    <p>***************************</p>
                </div>
            })}

        </LineAround>
        
        </div>

    </div>
  )
}

export default InsertExamQuestions