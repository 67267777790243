import './navbar.scss';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { useContext } from 'react';
import { userContext } from '../../context/userContext';
import { darkModeContext } from '../../context/darkModeContext';

import { useAuthStore } from "../../context/authStore";
import { useShallow } from "zustand/react/shallow";

const Navbar = () => {

  const { dispatch } = useContext(darkModeContext);
  const { role } = useContext(userContext);

  const { nick } = useAuthStore(
    useShallow((state) => ({ nick: state.nick })));
  
  return (
    <div className="navbar">
        <div className="wrapper">
            <div className="search">
                <input type="text" placeholder="Search ..." id="search" 
                       autoComplete="on"/>
                <SearchOutlinedIcon/>
            </div>
            <div className="items">
                <div className="item">
                    <LanguageOutlinedIcon className="icon" />
                    English
                </div>
                { role === 'admin' ?

                <>
                    <div className="item">
                        <DarkModeOutlinedIcon className="icon"  onClick={()=>dispatch({type:"TOGGLE"})}/>
                    </div>
                    <div className="item">
                        <FullscreenExitOutlinedIcon className="icon" />
                    </div>
                    <div className="item">
                        <NotificationsNoneOutlinedIcon className="icon" />
                        <div className="counter">1</div>
                    </div>
                    <div className="item">
                        <ChatBubbleOutlineOutlinedIcon className="icon" />
                        <div className="counter">2</div>
                    </div>
                    <div className="item">
                        <ListOutlinedIcon className="icon" />
                    </div>
                    <div className="item">
                        <img src="https://images.pexels.com/photos/86596/owl-bird-eyes-eagle-owl-86596.jpeg?auto=compress&cs=tinysrgb&w=600"
                            alt=""
                            className="avatar" />
                    </div>
                    </> : role !== 'guest' ?
                    <>
                    <div className="item">
                        Welcome, {nick}
                    </div>
                    <div className="item">
                        <img src="https://images.pexels.com/photos/86596/owl-bird-eyes-eagle-owl-86596.jpeg?auto=compress&cs=tinysrgb&w=600"
                            alt=""
                            className="avatar" />
                    </div>
                    </> :
                    <></>
                }
            </div>
        </div>
    </div>
  )
}

export default Navbar;