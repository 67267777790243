import './Input.scss';
import { useState } from 'react';

function Input({inputDesc,setFormDataOnChange,data,errMsg}) {

  const [ newValue, setNewValue ] 
     = useState(inputDesc.fillFromField && data[inputDesc.fillFromField]
       ? data[inputDesc.fillFromField] : '');

  const onChange = (e) => {
    setNewValue(e.target.value);
    setFormDataOnChange(e);
    /* setFormDataOnChange(inputDesc.dataType === "number" ? 
    +e.target.value : e.target.value, e.target.name); */
  }
  
  return (
    <div className="formInput">
        <label htmlFor={inputDesc.fName}>{inputDesc.label}
          {inputDesc.required && <span className="required">*</span>}
        </label>
        <input type={inputDesc.type} 
             placeholder={inputDesc.placeholder}
                        {...(inputDesc.auto && {autoComplete:inputDesc.auto})}
                        name={inputDesc.fName} id={inputDesc.fName} 
                        value={newValue || ''} onChange={onChange} 
                        data-type={inputDesc.dataType ? inputDesc.dataType : false}
                        />
        <p className={`errMsg ${errMsg && 'visible'}`}>{errMsg || ''}</p>
    </div>
  )
}

export default Input