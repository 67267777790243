import './FindYourself.scss';
import Input from '../../components/form/Input';
import Passwd from '../../components/form/Passwd';
import Select from '../../components/form/Select';

import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';

import { useState, useContext } from 'react';
import { userContext } from '../../context/userContext';

import { useCourseListStore } from "../../context/courseListStore";
import { useShallow } from "zustand/react/shallow";

import { useNavigate, Link } from 'react-router-dom';
import { getCourseList, checkCourseTempPasswd,getNeverLoggedCourseStudents } 
                                               from '../../services/courses';
//import { enterCourseInputs } from '../../formSource';

const FindYourself = () => {

  const navigate = useNavigate();
  const { dispatchUser } = useContext(userContext);

  const [coursePasswdMsg,setCoursePasswdMsg] = useState('');


  const [neverLoggedStudents,setNeverLoggedStudents] = useState([]);

  const { courseList, setCourseList, course, setCourse } = useCourseListStore(
    useShallow((state) => ({ courseList: state.courseList, 
                          setCourseList: state.setCourseList,
                                course: state.course,
                              setCourse: state.setCourse }))
  );

  const enterCourseInputs = [
    {
      id: 0,
      field: "select",
      label: "course",
      fName: "course",
      type: "select",
      placeholder: "",
      options: [{value:"",text:"-- Choose from the List --"}],
      getMoreOptions: ["getCourseList","courses.js","id","title",[courseList,setCourseList]],
      required: true,
      dataType: "number"
    },
    {
      id: 1,
      field: "input",
      label: "coursepasswd",
      fName: "coursepasswd",
      type: "password",
      placeholder: "password",
      auto: "current_passwd",
      required: true
    },
  ]

  const [formData, setFormData] = useState(
    enterCourseInputs.reduce((acc,input) => {
      return {...acc,[input.label]:''};
    },{})
  );

  const setFormDataOnChange = (e) => {
    setFormData({...formData, [e.target.name]: 
      e.target.getAttribute('data-type') === "number" ?
               +e.target.value : e.target.value});

  }

  const onSubmit = async (e) => {

    e.preventDefault();
    // we could perform here some formData validation

    setCoursePasswdMsg('');

    if (enterCourseInputs.every(el => formData[el.label])) {
      
      let courseFound = await checkCourseTempPasswd(formData.course, formData.passwd,courseList.list);
          
      if (courseFound) {

        //let courseList = await getCourseList(courseList,setCourseList);

        let currCourse = courseList.list.filter(cour => {
          
          return cour.id === formData.course
        })[0];
                
        setCourse(currCourse);

        let result = await getNeverLoggedCourseStudents(formData.course);
        
        setNeverLoggedStudents(result.data);
        
        setFormData(enterCourseInputs.reduce((acc,input) => {
          return {...acc,[input.label]:''};
        },{}));

      } else { // course not found

        setCoursePasswdMsg(`Wrong temporary password for the course`)

      }

    }

  }

  return (
    <div className="findYourself">
        <div className="top">
          <h1>Find Yourself</h1>
        </div>
        <div className="bottom">

          <div className="right">
            {
              course.id && neverLoggedStudents.length ?
              
              <>
              <h2>Course Found</h2>
              <h3>Students That Never Logged</h3>
              <ul>
                {neverLoggedStudents && 
                  neverLoggedStudents.map(stud => 
                  <li className="studentLink" key={stud.id}>
                    <SchoolTwoToneIcon className="icon" />
                    <Link to={`/first-login`} state={{user:stud}}>{stud.email}</Link>
                  </li>
                )}
              </ul>
              </>
              
              :
            
            <form onSubmit={onSubmit}>
              {
                coursePasswdMsg &&
                <p style={{"color":"red", "fontStyle": "italic"}}>{coursePasswdMsg}</p>
              }

              {
                enterCourseInputs.map((input) => { 
                  if (input.type === 'select') {

                    return (
                      <Select className="formInput" key={input.id}
                        selectDesc={input} setFormDataOnChange={setFormDataOnChange}
                      />
                    )

                  } else if (input.type === 'password') {

                    return (
                      <Passwd className="formInput" key={input.id}
                        inputDesc={input} setFormDataOnChange={setFormDataOnChange}
                      />
                    )

                  }
                  return (
                    <Input className="formInput" key={input.id}
                      inputDesc={input} setFormDataOnChange={setFormDataOnChange}
                    />
                  )
                })
              }

              <button>Send</button>
            </form>
            }
          </div>
        </div>
    </div>
  )
}

export default FindYourself;