import './GameGallery.scss';
import { useState, useEffect } from 'react';
import ItemGallery from '../../components/gallery/ItemGallery';
import axios from 'axios';

export default function GameGallery() {

    const [data, setData] = useState([]);

    useEffect(() => {

      const config = {
        headers:{
          "content-type": 'application/json;charset=utf-8'
        }
      };

      
      axios.get(`${window.apiBasePath}/games`, config)
      .then((response) => {
        console.log('response:');
        console.log(response);
        
        if (response.data.length > 0) {
          console.log(response.data);
          setData(response.data.map(game => {
            return {text: game.name,image:'',
                    linkTo: '/games/'+game.name_eng.toLowerCase()}
          }));

        } else {
          setData([]);
        }
        
      }).catch(err => {
        setData([]);
        console.log(err)
        return
      })

    },[])

  return (
    <div className="gameGallery">
        <h1 className="title">Games</h1>
        <ItemGallery galName="Games" 
          galDesc="Each game is designed to exercise some subject"
          itemList={data} />
    </div>
  )
}
