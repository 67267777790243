import './Modal.scss';
import { forwardRef, useImperativeHandle, useState } from 'react';
// useImperativeHandle should be avoided except of specific cases - this is from React docs
// popups is a good specific case as now the parent is not rendered together with popup actions

const Modal = (props,ref) => {

  // ref should be the second argument, this is why we're passing "props" even when not using them
  
  const [ modalState, setModalState ] = useState(false);

  useImperativeHandle(ref, () => ({

    openModal: () => setModalState(true)

  }))

  console.log(`TryUseImperativeHandleParent CHILD named "Modal" is rendered`);

  if (!modalState) return null;
  // actually here the popup is not rendered

  return (
    <div className="modal">
        <p>This is My Modal</p>
        <button onClick={()=>setModalState(false)}>Close Modal</button>
    </div>
  )
}

export default forwardRef(Modal);