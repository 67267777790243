import './datatable.scss';
import { DataGrid } from '@mui/x-data-grid';
import { columns } from "../../datatableSource";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';


const Datatable = ({what, link}) => {

  console.log('WHAT:',what)

    const listOfColumns = columns[what];
    const [data, setData] = useState([]);

    useEffect(() => {

      const config = {
        headers:{
          "content-type": 'application/json;charset=utf-8'
        }
      };

      
      axios.get(`${window.apiBasePath}/${what}`, config)
      .then((response) => {
        console.log('response:');
        console.log(response);
        
        if (response.data.length > 0) {
          console.log(response.data);
          setData(response.data);

        } else {
          setData([]);
        }
        
      }).catch(err => {
        setData([]);
        console.log(err)
        return
      })

    },[what])

    const handleDelete = (id) => {

      setData(data.filter(row => row.id !== id));

    }

    const actionColumn = [
        {
          field: "action",
          headerName: "Action",
          width: 200,
          renderCell: (params) => {
            return (
              <div className="cellAction">
                <Link to={`/${what}/test`} style={{ textDecoration: "none" }}>
                  <div className="viewButton">View</div>
                </Link>
                <div
                  className="deleteButton"
                  onClick={() => handleDelete(params.row.id)}
                >
                  Delete
                </div>
              </div>
            );
          },
        },
    ];

    return (
        <div className="datatable">
          <div className="datatableTitle">
            {what.split('').map((ch,i)=>i===0 ? ch.toUpperCase() 
                                              : ch.toLowerCase()).join('') + ':'}
            <Link to={`/${what}/new`} style={{textDecoration:"none"}}>
              Add New
            </Link>
          </div>
          <DataGrid className="datagrid"
              rows={data}
              columns={listOfColumns.concat(actionColumn)}
              pageSize={5}
              pageSizeOptions={[5,100]}
              rowsPerPageOptions={[5]}
              checkboxSelection
          />
        </div>
    )
}

export default Datatable;