import './TagButton.scss';
import { useState, useEffect } from 'react';

const TagButton = ({tag, removeSearchWord, addSearchWord}) => {

    
    const [colorClass,setColorClass] = useState('blue');
    const [chosen,setChosen] = useState(false);
    
    useEffect(()=>{
        const colorClasses = ['blue','golden','green','pink','red'];

        let rand = Math.floor(Math.random() * colorClasses.length);
        setColorClass(colorClasses[rand]);
    },[])

    const handleClick = () => {
        !chosen ? addSearchWord(tag) : removeSearchWord(tag);
        setChosen(!chosen);
    }


  return (
    <button className={`TagButton ${colorClass} ${chosen && "chosen"}`} 
            onClick={handleClick}>
        {tag.toLowerCase()}
    </button>
  )
}

export default TagButton