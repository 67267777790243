import './InputFile.scss';
import { useState } from 'react';

function InputFile(props) {

  const { inputFileDesc, setFormDataOnChange, data, errMsg, isDisabled } = props;

  const [newValue, setNewValue] 
      = useState(inputFileDesc.fillFromField && data[inputFileDesc.fillFromField]
    ? data[inputFileDesc.fillFromField] : inputFileDesc.placeholder);

  const style = {
    formInputFile: {
      width: props.width ? props.width : "40%",
      background: isDisabled ? "#eeeeee" : "inherit",
      color: isDisabled ? "#aaaaaa" : "inherit",
    },

  }

  const onChange = (e) => {
    setNewValue(e.target.value);
    setFormDataOnChange(e);
  }

  return (
    <div className="formInputFile" style={style.formInputFile} >
      <label htmlFor={inputFileDesc.fName}>{inputFileDesc.label}
        {inputFileDesc.required && <span className="required">*</span>}
      </label>
      <input type="file" name={inputFileDesc.fName} id={inputFileDesc.fName}
        value={newValue} onChange={onChange}  
        disabled={isDisabled ? true : false}
         />

        

      <p className={`errMsg ${errMsg && 'visible'}`}>{errMsg || ''}</p>
    </div>
  )
}

export default InputFile