import './InsertExamsAndGrades.scss';
import {useState} from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Box from '@mui/material/Box';

import AddExamMissions from './AddExamMissions';
import GradeMissions from './GradeMissions';
import InsertExamQuestions from './InsertExamQuestions';
import InsertExams from './InsertExams';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const InsertExamsAndGrades = () => {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  return (
    <div className="insertExamsAndGrades"> 
        <div className="title">
            Insert Exams and Grades
        </div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Insert Exams" {...a11yProps(0)} className="tab" />
                <Tab label="Add Exam Missions" {...a11yProps(1)} className="tab" />
                <Tab label="Insert Exam Questions" {...a11yProps(1)} className="tab" />
                <Tab label="Grade Exam" {...a11yProps(2)} className="tab" />
                <Tab label="Insert Existing Grades" {...a11yProps(3)} className="tab" />
            </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
            <InsertExams />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
            <AddExamMissions />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
            <InsertExamQuestions />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
            <GradeMissions />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
            Insert Existing Grades
        </CustomTabPanel>
    </div>
  )
}

export default InsertExamsAndGrades