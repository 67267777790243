import './ItemGallery.scss';
import GalleryItem from './GalleryItem';

export default function ItemGallery({galName,galDesc,itemList}) {
  return (
    <div className="itemGallery">
        { 
            galName && 
                <h2>galName</h2>
        }
        { 
            galDesc && 
                <p>galDesc</p>
        }
        <div className="itemContainer">

            {
                itemList.length && 
                    itemList.map((item,ix) =>
                    
                        <GalleryItem item={item} key={ix} />

                    )
            }

        </div>
    </div>
  )
}
