import './AddExamMissions.scss';
import { useState, useEffect, useRef } from 'react';
import { useCourseListStore } from "../../context/courseListStore";
import { useAuthStore } from "../../context/authStore";
import { useExamStore } from "../../context/examStore";

import { useShallow } from "zustand/react/shallow";

import { getExamsByCourse } from '../../services/exams';
import { getMissionsByExam, updateMissions, addMissions, deleteMission } from '../../services/examMissions';

import { examMissionColumns } from '../../dataStructure/examMissionColumns';

import LineAround from '../../components/containers/LineAround';
import EdiTable from '../../components/datatable/EdiTable';
import Select from '../../components/form/Select';

const AddExamMissions = () => {

        
  const { examList, setExamList,
    course, } = useCourseListStore(
        useShallow((state) => ({ 
            examList: state.examList,
            setExamList: state.setExamList,
            course: state.course,

    }))
  );
  const { exam, setExam,
    missions, setMissions, updateStoredMissions,
    addMissionsToStore, removeMissionById } = useExamStore(
        useShallow((state) => ({ 
            exam: state.exam,
            setExam: state.setExam,
            missions: state.missions,
            setMissions: state.setMissions,
            updateStoredMissions: state.updateMissions,
            addMissionsToStore: state.addMissions,
            removeMissionById: state.removeMissionById
    }))
  );

  const { accessToken } = useAuthStore(
    useShallow((state) => ({ accessToken: state.accessToken
                        }))
  );

  const addExamMissionsAmtRenders = useRef(0);
  addExamMissionsAmtRenders.current++;
  console.log(`addExamMissionsAmtRenders = ${addExamMissionsAmtRenders.current}`)
            
                     
  const [ focus, setFocus ] = useState(Object.keys(course).length === 0
                                         ? 'course' : 'exam');

  useEffect(() => {

    console.log(`Course has changed to ${course.id}`)

    async function fillExamsIfThereIsCourse() {

      if (Object.keys(course).length !== 0) { // if course is not an empty obj
        
        await getExamsByCourse(course.id, examList, setExamList);
        
      }
    }

    fillExamsIfThereIsCourse();

  }, [course])

  const selectExamDesc = {
    id: 0,
    field: "select",
    label: "Choose Exam to Add Questions to It:",
    fName: "exam",
    type: "select",
    placeholder: "",
    options: [{value:"",text:"-- Choose from the List --"}],
    getMoreOptions: ["getExamsByCourse","exams.js","id","title",[course.id,examList,setExamList]],
    required: true,
    dataType: "number"
  }


  const handleExamChange = async (e) => {
    let val = +e.target.value;
    if (val === exam.id) return;
    if (val === '') setFocus('exam')
    else {
        const examMissions = await getMissionsByExam(val);
        
        setMissions(examMissions);
        setFocus('examFile');
    }
    
    setExam(examList.list.filter(e => e.id === val)[0]);
  }

  const handleUpdateMissions = async (missionData) => {

    let result = await updateMissions(missionData, accessToken);

    if (result.success)  updateStoredMissions(missionData);

    return result;

  }

  const handleAddMissions = async (missionData) => {

    let result = await addMissions(missionData, accessToken);

    if (result.success) addMissionsToStore(missionData);

    return result;

  }

  const handleDeleteMission = async (id) => {

    let result = await deleteMission(id, accessToken);

    if (result.success) removeMissionById(id);

    return result;

  }

  return (
    <div className="addExamMissions">

        <div className="left">

        <LineAround focus={focus === 'exam'}>

            <Select className={`formInput`}  
                selectDesc={selectExamDesc} width="100%"
                setFormDataOnChange={handleExamChange}
                />
        
        </LineAround>


        <LineAround focus={focus !== 'course' } 
                    style={{backgroundColor:"rgba(0,128,129,0.5)"}}>
            {
                focus !== 'course' &&
                <EdiTable 
                  what={`Missions of ${exam.assignment_type} ${exam.title}, id: ${exam.id}`}
                  data={missions} listOfColumns={examMissionColumns} 
                  deleteById={handleDeleteMission} addRows={handleAddMissions} 
                  updateRows={handleUpdateMissions} editable={true}
                  outerHeight={'2000px'} tableForId='exam_and_assignment_missions' />
            }
        </LineAround>

        </div>



    </div>
  )
}

export default AddExamMissions