import axios from 'axios';
//import jwtDecode from 'jwt-decode';

const AGING = 60000; // msec

const config = {
    headers:{
      "content-type": 'application/json;charset=utf-8'
    }
};

const getAuthConfig = (accessToken) => {
    // return authorization header with jwt token
    //let accessToken = sessionStorage.getItem('accessToken');
    
    if (accessToken) {
        return { ...config, headers: {...config.headers,
                                         authorization: `Bearer ${accessToken}` }}
    } else {
        return config;
    };
    
}

export const getExams = async(args) => {

    const [examList,setExamList] = args;

    if (examList.list.length > 0 && Date.now() - examList.updated < AGING) {
        return examList.list;
    }

    try {
        let response = await axios.get(`${window.apiBasePath}/exams`);
        setExamList(response.data,undefined);
        return response.data;
    } catch (err) {

        console.log(err);
        return [];
    }

}

export const getExamsByCourse = async(...args) => {

    const [courseId, examList, setExamList ] = args;

    /* if (examList?.list?.length > 0 && examList?.courseId === courseId && Date.now() - examList?.updated < AGING) {
        return examList?.list;
    } */
    
    try {
        let response = await axios.get(`${window.apiBasePath}/exams/by-course/${courseId}`);
        setExamList(response.data,courseId);
        return response.data;
    } catch (err) {

        console.log(err);
        return [];
    }

}

export const getExamsByStudent = async(...args) => {

    //console.log(`args:\n`,args)
    const [studentId, examList, setExamList, controller, accessToken ] = args;

    //console.log(`EXAMLIST.LIST: \n`,examList.list)
    if (examList.list.length > 0 && examList.studentId === studentId && Date.now() - examList.updated < AGING) {
        return examList.list;
    }
    
    try {
        let response = await axios.get(`${window.apiBasePath}/exams/student/${studentId}`,
                     getAuthConfig(accessToken));
                   // {signal: controller.signal}, getAuthConfig(accessToken));
        setExamList(response.data,studentId);
        return response.data;
    } catch (err) {

        console.log(err.message);
        return [];
    }

}

export const getExamFileParsed = async(formData) => {

    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    }

    console.log(`Before axios.post()`)
    console.log(`formData:\n`,formData)

    try {
        let response = await axios.post(`${window.apiBasePath}/exams/parse-file`,formData,config);
        
        return response.data;
    } catch (err) {

        console.log(err);
        return [];
    }

}

export const updateExams = async(...args) => {

    //console.log(`args:\n`,args)
    // examData - array
    const [examData, accessToken ] = args;

    try {
        await axios.put(`${window.apiBasePath}/exams`,examData,
                     getAuthConfig(accessToken));
                   
        
        return {success: true};
    } catch (err) {

        console.log(err.message);
        return {success: false, message: err.message};
    }

}

export const addExams = async(...args) => {

    //console.log(`args:\n`,args)
    // examData - array
    const [examData, accessToken ] = args;

    try {
        await axios.post(`${window.apiBasePath}/exams`,examData,
                     getAuthConfig(accessToken));
                   
        
        return {success: true};
    } catch (err) {

        console.log(err.message);
        return {success: false, message: err.message};
    }

}

export const deleteExam = async(...args) => {

    //console.log(`args:\n`,args)
    // examData - array
    const [examId, accessToken ] = args;

    try {
        await axios.delete(`${window.apiBasePath}/exams/${examId}`,
                     getAuthConfig(accessToken));
                   
        
        return {success: true};
    } catch (err) {

        console.log(err.message);
        return {success: false, message: err.message};
    }

}

