import {createContext, useReducer} from 'react';
import styleReducer from "./styleReducer";

const INITIAL_STATE = {
    sideBarCollapse: false
}
const StyleContext = createContext(INITIAL_STATE);

export default StyleContext;

export const StyleContextProvider = ({children}) => {

    const [state,dispatchStyle] = useReducer(styleReducer,INITIAL_STATE);

    return (
        <StyleContext.Provider 
          value={{sideBarCollapse:state.sideBarCollapse,dispatchStyle}}>
            {children}
        </StyleContext.Provider>
    )
    
}