export const examMissionColumns = [
  { field: "id", headerName: "ID", width: 30, editable: true },
  { field: "exam_id", headerName: "Exam ID", width: 30, editable: true },
  { field: "mission_id", headerName: "Mission ID", width: 30, editable: true },
  { field: "mission_type", headerName: "Mission Type", width: 90, editable: true,
      type: 'singleSelect',
      valueOptions: ['partial', 'challenge', 'total', 'optional'],
},
  { field: "title", headerName: "Title", width: 120, editable: true },
  { field: "max_points", headerName: "Max Points", width: 30, editable: true },
  { field: "weight_percents", headerName: "Weight", width: 120, editable: true,
    renderCell: (params) => {
      return (
        <div>{params.row.weight_percents}{params.row.weight_percents && '%'}</div>
      );
    },
  },
  { field: "themes", headerName: "Themes", width: 120, editable: true },
  { field: "question_type", headerName: "Question Type", width: 100, editable: true,
      type: 'singleSelect',
      valueOptions: ['Code', 'MCQ', 'Debug', 'tech-action'], },
  { field: "difficulty", headerName: "Difficulty", width: 80, editable: true,
      type: 'singleSelect',
      valueOptions: ['hard', 'medium', 'easy'], },
  { field: "description", headerName: "The Question", width: 120, editable: true },
  { field: "initial_code", headerName: "Initial Code", width: 120, editable: true },
  { field: "answers", headerName: "Answers", width: 120, editable: true },
  { field: "right_answer", headerName: "Right Answer", width: 120, editable: true },
  { field: "length_minutes", headerName: "Length, minutes", width: 30, editable: true,
    renderCell: (params) => {
      return (
        <div>{params.row.length_hours}</div>
      );
    },
  },
  { field: "required", headerName: "If Required", width: 120, editable: true },
  {
    field: "link_to_mission",
    headerName: "Link to Mission",
    width: 230, editable: true,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <a className="cellImg" href={params.row.link_to_mission}>{params.row.link_to_mission}</a>
        </div>
      );
    },
  },
  {
    field: "who_created",
    headerName: "Who Created Mission",
    width: 100, editable: true,
  },
  {
    field: "who_is_grading",
    headerName: "Who Is Grading Mission",
    width: 100, editable: true,
  },
  {
    field: "when_created",
    headerName: "Create Date",
    width: 100, editable: true,
  },
  {
    field: "when_updated",
    headerName: "Update Date",
    width: 100, editable: true,
  },
  {
    field: "who_updated",
    headerName: "Who Updated",
    width: 100, editable: true,
  },
];

/* export const columns = {
  missions: missionColumns,
} */

