import './TryUseImperativeHandle.scss';
import { useRef, useState } from 'react';
import Modal from '../../components/modal/Modal';
import ModalNoRef from '../../components/modal/ModalNoRef';

const TryUseImperativeHandleParent = () => {

  const modalRef = useRef();
  const [ modalNoRefOpen, setModalNoRefOpen ] = useState(false);

  const handleOpenModal = () => {
    modalRef.current.openModal()
  }

  const closeModalNoRef = () => {
    setModalNoRefOpen(false);
  }

  console.log(`Parent TryUseImperativeHandleParent rendered`);

  return (
    <main className="tryImperativeHandleParent">
      <p>TryUseImperativeHandle PARENT</p>
      <Modal ref={modalRef} />
      <button onClick={handleOpenModal}>Open Modal</button>
      <ModalNoRef isOpen={modalNoRefOpen} closeModal={closeModalNoRef} />
      <button onClick={()=>setModalNoRefOpen(true)}>Open Modal No Ref</button>
    </main>
  )
}

export default TryUseImperativeHandleParent;