import './Passwd.scss';
import { useState } from 'react';
import IconButton from "@mui/material/IconButton";
//import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {default as MuiInput} from "@mui/material/Input";

function Passwd({inputDesc,setFormDataOnChange, data, errMsg}) {

  const [ newValue, setNewValue ] 
     = useState(inputDesc.fillFromField && data[inputDesc.fillFromField]
    ? data[inputDesc.fillFromField] : '');
  const [ showPass, setShowPass ] = useState(false);

  const onChange = (e) => {
    setNewValue(e.target.value);
    setFormDataOnChange(e);
    /* setFormDataOnChange(inputDesc.dataType === "number" ? 
    +e.target.value : e.target.value, e.target.name); */
  }

  const changeShowPass = () => {

    setShowPass(!showPass);

  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
};

  return (
    <div className="formInput passwd">
        <label htmlFor={inputDesc.fName}>{inputDesc.label}
          {inputDesc.required && <span className="required">*</span>}
        </label>
        <MuiInput type={showPass ? "text" : "password"} 
            placeholder={inputDesc.placeholder}
                        {...(inputDesc.auto && {autoComplete:inputDesc.auto})}
                        name={inputDesc.fName} id={inputDesc.fName} 
                        value={newValue || ''} onChange={onChange} 
                        data-type={inputDesc.dataType ? inputDesc.dataType : false} 
                        endAdornment={
                          <InputAdornment position="end">
                              <IconButton
                                  onClick={
                                      changeShowPass
                                  }
                                  onMouseDown={
                                      handleMouseDownPassword
                                  }
                              >
                                  {showPass ? (
                                      <Visibility />
                                  ) : (
                                      <VisibilityOff />
                                  )}
                              </IconButton>
                          </InputAdornment>
                      }
                        
        />
        <p className={`errMsg ${errMsg && 'visible'}`}>{errMsg || ''}</p>
    </div>
  )
}

export default Passwd