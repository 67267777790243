export const gradeMissionColumns = [
  { field: "id", headerName: "ID", width: 30, editable: true },
  { field: "exam_id", headerName: "Exam ID", width: 30, editable: true },
  { field: "mission_id", headerName: "Mission ID", width: 30, editable: true },
  { field: "student_id", headerName: "Student ID", width: 30, editable: true },
  { field: "grade_type", headerName: "Grade Type", width: 90, editable: true,
      type: 'singleSelect',
      valueOptions: ['partial', 'challenge', 'total', 'optional'],
  },
  { field: "title", headerName: "Title", width: 120, editable: true },
  { field: "max_points", headerName: "Max Points", width: 40, editable: true },
  { field: "grade", headerName: "Grade", width: 40, editable: true },
  { field: "comment", headerName: "Comment", width: 120, editable: true },
  {
    field: "link_to_student_answer",
    headerName: "Link to Student Answer",
    width: 230, editable: true,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <a className="cellImg" href={params.row.link_to_student_answer}>{params.row.link_to_student_answer}</a>
        </div>
      );
    },
  }


];

/* export const columns = {
  missions: missionColumns,
} */

