import { Outlet, Navigate } from 'react-router-dom';

export const AdminRoutes = ({role}) => {

    return role === 'admin' ? <Outlet /> 
         : role === 'guest' ? <Navigate to='/login' />
         : role === 'viewer' ? <Navigate to='/viewer/grades' />
         : <Navigate to='/student/grades' />

}

export const NotLoggedRoutes = ({role}) => {

    return role === 'guest' ? <Outlet /> : <Navigate to="/" />

}

export const StudentRoutes = ({role}) => {

    return role === 'student' ? <Outlet /> : <Navigate to="/" />

}

export const ViewerRoutes = ({role}) => {

    return role === 'viewer' ? <Outlet /> : <Navigate to="/" />

}