import './Select.scss';
import { useState, useEffect } from 'react'

function Select(props) {

  const { selectDesc, setFormDataOnChange, data, errMsg, isDisabled } = props;

  const [newValue, setNewValue] 
      = useState(selectDesc.fillFromField && data[selectDesc.fillFromField]
    ? data[selectDesc.fillFromField] : selectDesc.placeholder);
  const [selectOptions, setSelectOptions] = useState(selectDesc.options);

  const style = {
    formSelect: {
      width: props.width ? props.width : "40%",
      background: isDisabled ? "#eeeeee" : "inherit",
      color: isDisabled ? "#aaaaaa" : "inherit",
    },

  }

  const onChange = (e) => {
    setNewValue(e.target.value);
    setFormDataOnChange(e);
  }

//console.log(`SELECT selectDesc.getMoreOptions:\n`,selectDesc.getMoreOptions)

  useEffect(() => {

    if (selectDesc.getMoreOptions && !isDisabled) {

      const importAndUseFunction = async () => {

        const module = await import(`../../services/${selectDesc.getMoreOptions[1]}`);

        let funcParams = selectDesc.getMoreOptions[4] || [];
        const newOptions = await module[selectDesc.getMoreOptions[0]](...funcParams);
        
        setSelectOptions(selectDesc.options.concat(
          newOptions.map(opt => {
            return {value:opt[selectDesc.getMoreOptions[2]],
                    text: opt[selectDesc.getMoreOptions[3]]}
          })
        ));


      }

      importAndUseFunction();

    }

  }, [isDisabled])

  return (
    <div className="formSelect" style={style.formSelect} >
      <label htmlFor={selectDesc.fName}>{selectDesc.label}
        {selectDesc.required && <span className="required">*</span>}
      </label>
      <select name={selectDesc.fName} id={selectDesc.fName}
        value={newValue} onChange={onChange}  disabled={isDisabled ? true : false}
        data-type={selectDesc.dataType ? selectDesc.dataType : false} >

        {selectOptions.map((option, i) => {
          return <option key={i} value={option.value}>{option.text}</option>
        })}

      </select>
      <p className={`errMsg ${errMsg && 'visible'}`}>{errMsg || ''}</p>
    </div>
  )
}

export default Select