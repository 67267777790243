import axios from 'axios';
//import jwtDecode from 'jwt-decode';

const AGING = 60000; // msec

const config = {
    headers:{
      "content-type": 'application/json;charset=utf-8'
    }
};

const getAuthConfig = (accessToken) => {
    // return authorization header with jwt token
    //let accessToken = sessionStorage.getItem('accessToken');
    
    if (accessToken) {
        return { ...config, headers: {...config.headers,
                                         authorization: `Bearer ${accessToken}` }}
    } else {
        return config;
    };
    
}


export const getMaxIdOfTable = async(...args) => {

    //console.log(`args:\n`,args)
    const [tableName, accessToken ] = args;

    try {
        let response = await axios.get(`${window.apiBasePath}/general/max-id/${tableName}`,
                     getAuthConfig(accessToken));
                   
        console.log(response.data)
        return {...response.data,success:true};
    } catch (err) {

        console.log(err.message);
        return {success:false, message: err.response.data.message};
    }

}

