import './ShowGradesByExam.scss';

import { useCourseListStore } from "../../../context/courseListStore";
import { useShallow } from "zustand/react/shallow";

import GradesOfSingleExam from './GradesOfSingleExam';

const ShowGradesByExam = () => {

  const { examList } = useCourseListStore(
    useShallow((state) => ({ examList: state.examList }))
  );

  

  return (
    <div className="showGradesByExam">

      {/* <div className="title">No Exams and Assignments Yet</div> : */} 
        {
          examList.list.length === 0 ? 
          <div className="title">Loading ...</div> : 
          examList.list.map(e => {
            // console.log(`exam:\n`,e)
            return <GradesOfSingleExam key={`whole_exam_${e.id}`} aboutExam={e} />
          })
        }
        {/* {
          examList.list.length === 0 ? 
          <div className="title">No Exams and Assignments Yet</div> : 
          examList.list.map(e => {
            // console.log(`exam:\n`,e)
            return <GradesOfSingleExam key={`whole_exam_${e.id}`} aboutExam={e} />
          })
        } */}

    </div>
  )
}

export default ShowGradesByExam