import './SidebarItem.scss';
import { Link } from 'react-router-dom';

const SidebarItem = (props) => {

  const {itemDesc, logout, dispatchUser, setActive, 
    idx, isActive, navCollapse,role, narrow,
    emptyAuthStore, emptyStudentStore, accessToken } = props;

  return (
    <div className={`sidebarItem ${(isActive && itemDesc.type!=="title") && "active"}`}>
        { itemDesc.type === "title" ? 

              <p className="title">{itemDesc.name}</p> 

            :

            <li onClick={()=>{setActive(idx)}}>
                { itemDesc.link &&
                <Link to={itemDesc.link} style={{ textDecoration:"none" }} className="link">

                        {itemDesc.icon}
                        
                        <span className={`${navCollapse ? "navCollapse" : ""} ${narrow ? "narrow" : ""}`}>
                          {itemDesc.name}
                        </span>

                </Link> 
                }
                { itemDesc.onClick &&
                <div onClick={() => logout(dispatchUser,role,emptyAuthStore,emptyStudentStore,accessToken)} style={{ textDecoration:"none" }} className="link">

                        {itemDesc.icon}
                        <span className={navCollapse ? "navCollapse" : ""}>{itemDesc.name}</span>

                </div> 
                }
            </li>

        }
    </div>
  )
}

export default SidebarItem;