import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EmojiNatureOutlinedIcon from '@mui/icons-material/EmojiNatureOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import SatelliteIcon from '@mui/icons-material/Satellite';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SensorOccupiedOutlinedIcon from '@mui/icons-material/SensorOccupiedOutlined';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SettingsSystemDaydreamOutlinedIcon from '@mui/icons-material/SettingsSystemDaydreamOutlined';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';

//import PythonIcon from '../../assets/img/icons/python-svgrepo-com.svg';

import SvgIcon from '@mui/material/SvgIcon';

export const sidebarEntries = {
    guest: {
        firstActive: 0,
        entries: [
        {
            link: "/login",
            icon: <LoginOutlinedIcon className="icon" />,
            name: "Login"
        },
        {
            link: "/find-yourself",
            icon: <ContentPasteSearchOutlinedIcon className="icon" />,
            name: "Login 1st Time - Find Yourself"
        },
        {
            link: "/sign-up",
            icon: <SensorOccupiedOutlinedIcon className="icon" />,
            name: "Sign Up"
        }
        ]
    },

    admin: {
        firstActive: 1,
        entries: [
        {
            type: "title",
            name: "MAIN"
        },
        {
            link: "/",
            icon: <DashboardIcon className="icon" />,
            name: "Dashboard"
        },
        {
            type: "title",
            name: "LISTS"
        },
        {
            link: "/users",
            icon: <PersonOutlineIcon className="icon" />,
            name: "Users"
        },
        {
            link: "/students",
            icon: <SchoolOutlinedIcon className="icon" />,
            name: "Students"
        },
        {
            link: "/exams/grades",
            icon: <SchoolOutlinedIcon className="icon" />,
            name: "Grades"
        },
        {
            link: "/themes",
            icon: <LocalLibraryOutlinedIcon className="icon" />,
            name: "Themes"
        },
        {
            type: "title",
            name: "EXERCISE"
        },
        {
            link: "/games",
            icon: <SportsHandballIcon className="icon" />,
            name: "Games"
        },
        {
            type: "title",
            name: "USEFUL"
        },
        {
            link: "/useful/links",
            icon: <SubscriptionsOutlinedIcon className="icon" />,
            name: "Links"
        },
        {
            link: "/calendar",
            icon: <CalendarMonthOutlinedIcon className="icon" />,
            name: "Calendar"
        },
        {
            link: "/images",
            icon: <SatelliteIcon className="icon" />,
            name: "Images"
        },
        {
            link: "/stats",
            icon: <InsertChartIcon className="icon" />,
            name: "Stats"
        },
        {
            link: "/notifications",
            icon: <NotificationsNoneIcon className="icon" />,
            name: "Notifications"
        },
        {
            type: "title",
            name: "SERVICE"
        },
        {
            link: "/schools",
            icon: <SettingsSystemDaydreamOutlinedIcon className="icon" />,
            name: "Schools"
        },
        {
            link: "/exams/insert",
            icon: <SettingsSystemDaydreamOutlinedIcon className="icon" />,
            name: "Insert Exams and Grades"
        },
        {
            link: "/logs",
            icon: <PsychologyOutlinedIcon className="icon" />,
            name: "Logs"
        },
        {
            link: "/exams",
            icon: <SettingsApplicationsIcon className="icon" />,
            name: "Exams and Graded"
        },
        {
            link: "/missions",
            icon: <SettingsApplicationsIcon className="icon" />,
            name: "Missions"
        },
        {
            type: "title",
            name: "USER"
        },
        {
            link: "/users/1",
            icon: <AccountCircleOutlinedIcon className="icon" />,
            name: "Profile"
        },
        {
            onClick: "() => logout(dispatchUser)",
            icon: <ExitToAppIcon className="icon" />,
            name: "Logout"
        }
    ]
    },
    student: {
        firstActive: 0,
        entries: [
        {
            type: "title",
            name: "MAIN"
        },
        {
            link: "/",
            icon: <DashboardIcon className="icon" />,
            name: "My Results"
        },
        {
            type: "title",
            name: "LISTS"
        },
        {
            link: "/assignments",
            icon: <LocalLibraryOutlinedIcon className="icon" />,
            name: "Assignments"
        },
        {
            type: "title",
            name: "SERVICES"
        },
        {
            link: "/services/report-bug",
            icon: <EmojiNatureOutlinedIcon className="icon" style={{fontSize: "1.7rem"}} />,
            name: "Report Bug"
        },
        {
            link: "/services/suggest-feature",
            icon: <MapsUgcOutlinedIcon className="icon" />,
            name: "Suggest Feature"
        },
        {
            type: "title",
            name: "USEFUL"
        },
        {
            link: "/useful/links",
            icon: <SubscriptionsOutlinedIcon className="icon" />,
            name: "Links"
        },
        {
            link: "/student/python",
            icon: <ContentPasteSearchOutlinedIcon className="icon" />,
            //icon: <SvgIcon component={PythonIcon} inheritViewBox className="icon" />,
            name: "Python"
        },
        {
            type: "title",
            name: "USER"
        },
        {
            link: "/users/1",
            icon: <AccountCircleOutlinedIcon className="icon" />,
            name: "Profile"
        },
        {
            onClick: "() => logout(dispatchUser)",
            icon: <ExitToAppIcon className="icon" />,
            name: "Logout"
        }
    ]
    },
    smb: {
        firstActive: 0,
        entries: [
        {
            type: "title",
            name: "MAIN"
        },
        {
            link: "/",
            icon: <DashboardIcon className="icon" />,
            name: "My Results"
        },
        {
            type: "title",
            name: "LISTS"
        },
        {
            link: "/users",
            icon: <PersonOutlineIcon className="icon" />,
            name: "Users"
        },
        {
            link: "/students",
            icon: <SchoolOutlinedIcon className="icon" />,
            name: "Students"
        },
        {
            link: "/themes",
            icon: <LocalLibraryOutlinedIcon className="icon" />,
            name: "Themes"
        },
        {
            type: "title",
            name: "USEFUL"
        },
        {
            link: "/calendar",
            icon: <CalendarMonthOutlinedIcon className="icon" />,
            name: "Themes"
        },
        {
            link: "/images",
            icon: <SatelliteIcon className="icon" />,
            name: "Images"
        },
        {
            link: "/stats",
            icon: <InsertChartIcon className="icon" />,
            name: "Stats"
        },
        {
            link: "/notifications",
            icon: <NotificationsNoneIcon className="icon" />,
            name: "Notifications"
        },
        {
            type: "title",
            name: "SERVICE"
        },
        {
            link: "/system-health",
            icon: <SettingsSystemDaydreamOutlinedIcon className="icon" />,
            name: "System Health"
        },
        {
            link: "/logs",
            icon: <PsychologyOutlinedIcon className="icon" />,
            name: "Logs"
        },
        {
            link: "/settings",
            icon: <SettingsApplicationsIcon className="icon" />,
            name: "Settings"
        },
        {
            type: "title",
            name: "USER"
        },
        {
            link: "/users/1",
            icon: <AccountCircleOutlinedIcon className="icon" />,
            name: "Profile"
        },
        {
            onClick: "() => logout(dispatchUser)",
            icon: <ExitToAppIcon className="icon" />,
            name: "Logout"
        }
    ]
    },
    viewer: {
        firstActive: 0,
        entries: [
        {
            type: "title",
            name: "MAIN"
        },
        {
            link: "/",
            icon: <DashboardIcon className="icon" />,
            name: "Course Results"
        },
        {
            type: "title",
            name: "LISTS"
        },
        {
            link: "/subjects",
            icon: <LocalLibraryOutlinedIcon className="icon" />,
            name: "Subjects"
        },
        {
            type: "title",
            name: "SERVICES"
        },
        {
            link: "/services/report-bug",
            icon: <EmojiNatureOutlinedIcon className="icon" style={{fontSize: "1.7rem"}} />,
            name: "Report Bug"
        },
        {
            link: "/services/suggest-feature",
            icon: <MapsUgcOutlinedIcon className="icon" />,
            name: "Suggest Feature"
        },
        {
            type: "title",
            name: "USER"
        },
        {
            link: "/users/1",
            icon: <AccountCircleOutlinedIcon className="icon" />,
            name: "Profile"
        },
        {
            onClick: "() => logout(dispatchUser)",
            icon: <ExitToAppIcon className="icon" />,
            name: "Logout"
        }
    ]
    }
}