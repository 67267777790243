const userReducer = (state, action) => {

    switch(action.type) {
        case "ADMIN": {
            return {
                role: 'admin'
            };
        }
        case "STUDENT": {
            return {
                role: 'student'
            };
        }
        case "TEACHER": {
            return {
                role: 'teacher'
            };
        }
        case "VIEWER": {
            return {
                role: 'viewer'
            };
        }
        case "LOGOUT": {
            return {
                role: 'guest'
            };
        }
        default: {
            return state;
        }
    }

}

export default userReducer;