export const examColumns = [
  { field: "id", headerName: "ID", width: 30 },
  { field: "assignment_type", headerName: "What", width: 30, editable: true },
  { field: "title", headerName: "title", width: 60, editable: true },
  { field: "start_date", headerName: "Start", width: 60, editable: true },
  { field: "due_date", headerName: "Due Date", width: 60, editable: true },
  { field: "themes", headerName: "Themes", width: 60, editable: true },
  { field: "weight_percents", headerName: "Weight", width: 60, editable: true, 
    renderCell: (params) => {
      return (
        <div>{params.row.weight_percents}{params.row.weight_percents && '%'}</div>
      );
    },
  },
  { field: "short_desc", headerName: "Short Descr", width: 120, editable: true },
  { field: "description", headerName: "Description", width: 100, editable: true },
  { field: "is_finished", headerName: "Finished?", width: 50, editable: true },
  { field: "length_hours", headerName: "Length, hours", width: 30, editable: true,
    renderCell: (params) => {
      return (
        <div>{params.row.length_hours}</div>
      );
    },
  },
  { field: "required", headerName: "Required?", width: 50, editable: true },
  {
    field: "link_to_mission",
    headerName: "Link to Assignment",
    width: 230, editable: true,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <a className="cellImg" href={params.row.link_to_assignment}>{params.row.link_to_assignment}</a>
        </div>
      );
    },
  },
  {
    field: "link_to_student_answers",
    headerName: "Link to Stud. Answers",
    width: 230, editable: true,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <a className="cellImg" href={params.row.link_to_student_answers}>{params.row.link_to_student_answers}</a>
        </div>
      );
    },
  },
  {
    field: "who_created",
    headerName: "Who Created",
    width: 100, editable: true,
  },
  {
    field: "who_is_grading",
    headerName: "Who Is Grading",
    width: 100, editable: true,
  },
  {
    field: "when_created",
    headerName: "Create Date",
    width: 100, editable: true,
  },
  {
    field: "when_updated",
    headerName: "Update Date",
    width: 100, editable: true,
  },
  {
    field: "who_updated",
    headerName: "Who Updated",
    width: 100, editable: true,
  },
];

/* export const columns = {
  missions: examColumns,
} */

