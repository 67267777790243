import { create } from 'zustand';
import {jwtDecode} from 'jwt-decode';

import { persist, createJSONStorage } from 'zustand/middleware';


export const useAuthStore = create(

  persist(
    
    (set,get) => ({
    
    accessToken: '',
    
    nick: '', role: '',
    
    setAccessToken: (currToken) => set({ accessToken: currToken,
      nick: jwtDecode(currToken).nick, role: jwtDecode(currToken).role }),
      
      emptyStore: () => set({
        accessToken:'',
        nick: '',
        role: ''
      })
      
    }),
    {
      name: 'class-d', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },
  )
)