const styleReducer = (state, action) => {

    switch(action.type) {
        case "SIDEBAR COLLAPSE": {
            return {
                sideBarCollapse: true
            };
        }
        case "SIDEBAR UNCOLLAPSE": {
            return {
                sideBarCollapse: false
            };
        }
        case "SIDEBAR TOGGLE COLLAPSE": {
            return {
                sideBarCollapse: !state.sideBarCollapse
            };
        }
        default: {
            return state;
        }
    }

}

export default styleReducer;