import './SignUp.scss';

import MacroOffOutlinedIcon from '@mui/icons-material/MacroOffOutlined';
import PersonAddDisabledOutlinedIcon from '@mui/icons-material/PersonAddDisabledOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';

function SignUp({ inputs }) {

  return (
    <div className="under-construction">

        <div className="icon-container">

            <PersonAddDisabledOutlinedIcon className="icon" />
            <ThumbDownOffAltOutlinedIcon className="icon" />
            <MacroOffOutlinedIcon className="icon" />

        </div>

        <hr />
        <h1>This option is currently disabled</h1>
    </div>
  )
}

export default SignUp