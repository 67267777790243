import './Modal.scss';

const ModalNoRef = ({isOpen, closeModal}) => {

  console.log(`TryUseImperativeHandleParent CHILD named "Modal No Ref" is rendered`);

  if (!isOpen) return null;

  return (
    <div className="modal">
        <p>This is My Modal No Ref</p>
        <button onClick={closeModal}>Close Modal No Ref</button>
    </div>
  )
}

export default ModalNoRef;