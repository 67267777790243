import { create } from 'zustand';

export const useExamStore = create((set, get) => ({

  exam: {},
  missions: [],
  
  setExam: (currExam) => set({ exam: currExam }), 
  resetExam: () => set({ exam: {}, missions: [] }), 
  setMissions: (currExamMissions) => set({ missions: currExamMissions }), 
  removeMissions: (idAr) => set((state) => ({ 
    missions: state.missions.filter(m => !idAr.includes(m.id)) 
  })), 
  removeMissionById: (id) => set((state) => ({ 
    missions: state.missions.filter(m => m.id !== id) 
  })), 
  addMissions: (missionsAr) => set((state) => ({ 
    missions: state.missions.concat(missionsAr) 
  })), 

  updateMissions: (listToUpdate) => {
    const oldList = get().missions;
    
    const list = oldList.map(m => {
      let updatedM = listToUpdate.filter(mis => mis.id === m.id && mis.exam_id === m.exam_id);
      if (updatedM.length === 0) return m;
      return updatedM[0];
    })
    set({ missions: list })
  },

}))