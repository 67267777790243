import './InsertExams.scss';
import { useState, useEffect, useRef } from 'react';
import { useCourseListStore } from "../../context/courseListStore";
import { useAuthStore } from "../../context/authStore";

import { useShallow } from "zustand/react/shallow";

import { getExamsByCourse, updateExams, addExams, deleteExam } from '../../services/exams';
import { examColumns } from '../../dataStructure/examColumns';

import LineAround from '../../components/containers/LineAround';
import EdiTable from '../../components/datatable/EdiTable';
import Select from '../../components/form/Select';

const InsertExams = () => {

        
  const { courseList, setCourseList, examList, setExamList,
    course, setCourse, removeExam, updateExamList, addExamList } = useCourseListStore(
        useShallow((state) => ({ courseList: state.courseList, 
            setCourseList: state.setCourseList,
            examList: state.examList,
            setExamList: state.setExamList,
            course: state.course,
            setCourse: state.setCourse,
            removeExam: state.removeExam,
            updateExamList: state.updateExamList,
            addExamList: state.addExamList
    }))
  );

  const { accessToken } = useAuthStore(
    useShallow((state) => ({ accessToken: state.accessToken
                        }))
  );

  const insertExamsAmtRenders = useRef(0);
  insertExamsAmtRenders.current++;
  console.log(`insertExamsAmtRenders = ${insertExamsAmtRenders.current}`)
            
                     
  const [ focus, setFocus ] = useState(Object.keys(course).length === 0
                                         ? 'course' : 'exam');

  useEffect(() => {

    console.log(`Course has changed to ${course.id}`)

    async function fillExamsIfThereIsCourse() {

      if (Object.keys(course).length !== 0) { // if course is not an empty obj
        
        await getExamsByCourse(course.id, examList, setExamList);
        
      }
    }

    fillExamsIfThereIsCourse();

  }, [course])

  const selectCourseDesc = {
    id: 0,
    field: "select",
    label: "Choose the Course to See the List of Exams",
    fName: "course",
    type: "select",
    placeholder: "",
    fillFromField: "id",
    options: [{value:"",text:"-- Choose from the List --"}],
    getMoreOptions: ["getCourseList","courses.js","id","title",[courseList,setCourseList]],
    required: true,
    dataType: "number"
  }


  const handleCourseChange = (e) => {
    console.log(`In handleCourseChange`)
    let val = +e.target.value;
    if (val === course.id) return;
    if (val === '') setFocus('course')
    else setFocus('exam');
    setCourse(courseList.list.filter(c => c.id === val)[0]);
  }

  const handleUpdateExams = async (examData) => {

    let result = await updateExams(examData, accessToken);

    if (result.success)  updateExamList(examData);

    return result;

  }

  const handleAddExams = async (examData) => {

    let result = await addExams(examData, accessToken);

    if (result.success) addExamList(examData);

    return result;

  }

  const handleDeleteExam = async (id) => {

    let result = await deleteExam(id, accessToken);

    if (result.success) removeExam(id);

    return result;

  }

  return (
    <div className="insertExams">

        <div className="left">

        <LineAround focus={focus === 'course'}>

            <Select className="formInput" selectDesc={selectCourseDesc} 
                setFormDataOnChange={handleCourseChange} width="100%"
                data={{id: course.id}}
            />

        </LineAround>


        <LineAround focus={focus !== 'course' } 
                    style={{backgroundColor:"rgba(0,128,129,0.5)"}}>
            {
                focus !== 'course' &&
                <EdiTable 
                  what={`Exams of course ${course.title}, id: ${course.id}`}
                  data={examList.list} listOfColumns={examColumns} 
                  deleteById={handleDeleteExam} addRows={handleAddExams} 
                  updateRows={handleUpdateExams} editable={true}
                  outerHeight={'2000px'} tableForId='exams_and_graded_assignments' />
            }
        </LineAround>

        </div>



    </div>
  )
}

export default InsertExams