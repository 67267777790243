import './EdiTable.scss';
import { DataGrid, GridRowModes,
  GridRowEditStopReasons, GridToolbarContainer,
  GridActionsCellItem, } from '@mui/x-data-grid';

import { useState, useEffect } from 'react';

import { getMaxIdOfTable } from '../../services/generalGetData';
import { useAuthStore } from "../../context/authStore";

import { useShallow } from "zustand/react/shallow";

import ExcelExport from './ExcelExport';
//import RUSure from '../popups/RUSure';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/LibraryAdd';
import CancelIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
//import ViewIcon from '@mui/icons-material/Visibility';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditToolbar({ setRows, setRowModesModel, getNextMaxId,  
   makeEmptyRow, data, what }) {
  

  const handleClick = () => {
    const newMaxId = getNextMaxId();
    
    setRows((oldRows) => [...oldRows, makeEmptyRow(newMaxId)]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newMaxId]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <div className="ediTableTitle">
        {what.split('').map((ch,i)=>i===0 ? ch.toUpperCase() 
                                              : ch.toLowerCase()).join('') + ':'}
      </div>
      <Button color="primary"
        className="btn addRecord" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
      <ExcelExport data={data} filename={what.replaceAll(' ','_')} />
    </GridToolbarContainer>
  );
}

const EdiTable = ({what, listOfColumns, data, tableForId, deleteById,
                  columnsVisible, needsCheckboxes, updateRows, addRows, outerHeight }) => {
  const [rows, setRows] = useState(data);
  const [rowModesModel, setRowModesModel] = useState({});

  const { accessToken } = useAuthStore(
    useShallow((state) => ({ accessToken: state.accessToken
                        }))
  );

  useEffect(() => {
    setRows(data)
  },[data])

  /* const [openRUSure,setOpenRUSure] = useState(false);
  const [ifDelete, setIfDelete] = useState(false);
  const rowToDelete = {id: '', txt: ''} */

  const getMaxId = async () => {
    let result = await getMaxIdOfTable(tableForId,accessToken)

    console.log(`inside getMaxId, result:\n`,result)
    
    return result.success ? {dataType:result.dataType, maxId: result.maxId}
                          : {dataType:'string',maxId:'0'};
    
  };

  const [maxId, setMaxId] = useState({});
  
  useEffect(() => {
    console.log(`calculating maxId in useEffect`);

    (async function() {
      let newObj = await getMaxId()
      setMaxId(newObj)
    })()
    
  },[tableForId]);

  console.log(`FINALLY typeof maxid = ${maxId.dataType}, maxId = `, maxId.maxId);
  
  const getNextMaxId  = () => {
    
    console.log(`Inside getNextMaxId dataType= ${maxId.dataType}, maxId = `, maxId.maxId);
    let newMaxId;

    if (maxId.dataType === 'number') {
      newMaxId = maxId.maxId + 1;
    } else {
      let maxIdStart = maxId.maxId.slice(0,-1);
      let lastCode = maxId.maxId[maxId.maxId.length - 1].charCodeAt[0];

      // These are digits or letters, move to the next
      if (lastCode < 57 || lastCode >= 65 && lastCode < 90 || lastCode >= 97 && lastCode < 122) {
        newMaxId = maxIdStart + String.fromCharCode(lastCode + 1);
      } else if (lastCode === 57) { // if this is '9', jump to 'A'
        newMaxId = maxIdStart + 'A'
      } else if (lastCode === 90) { // if this is 'Z', jump to 'a'
        newMaxId = maxIdStart + 'a'
      } else {
        newMaxId = maxId.maxId + '0' // if this is 'z' - no more chars, add new char
      }
    }

    setMaxId({...maxId,maxId:newMaxId});
    return newMaxId;

  }
  /////////////// MaxId ended /////////////////////


  const makeEmptyRow = (newId) => {

    return listOfColumns.reduce((row,col) => {
      return {...row,[col.field]:col.field === 'id' ? newId : ''}
    },{isNew: true})
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => async () => {
    console.log(`************************************`)
    console.log(`in handleSaveClick: id = \n`, id)
    console.log(`************************************`)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

    
  };

  const handleDeleteClick = (id) => async() => {
    console.log('&&&&&&&&&&&&&&&&&&&&&')
    console.log('HANDLE DELETE')
    console.log('&&&&&&&&&&&&&&&&&&&&&')
    /* rowToDelete = rows.find((row) => row.id === id)
    rowToDelete.txt = Object.values(rowToDelete).join(', ')
    setOpenRUSure(true); */
    let result = await deleteById(id);
    if (result.success) {
      setRows(rows.filter((row) => row.id !== id));
      toast.success(`The changes applied successfully`, {
        autoClose: 5000,
        position: "top-left",
      });
    } else {
      toast.error(result.message, {
        autoClose: 5000,
        position: "top-left",
      });
    }
  };

  const handleCancelClick = (id) => async () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
      const nowMaxId = await getMaxId();
      setMaxId(nowMaxId)
    }
  };

  const handleCopyClick = (id) => () => {

    let newMaxId = getNextMaxId();
    const newRow = rows.filter(row => row.id === id)[0];
    setRows((oldRows) => [...oldRows, {...newRow, id: newMaxId, isNew: true}]);
    
    setMaxId({...maxId,maxId:newMaxId})
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newMaxId]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    })); 

  }

  const processRowUpdate = async (newRow) => {
    console.log(`processRowUpdate runs, the row is \n`, newRow)
    console.log(`************************************`)
    console.log(`id = \n`, newRow.id)
    console.log(`************************************`)
    let result;

    if (newRow?.isNew) {
      result = await addRows([newRow]);
    } else {
      result = await updateRows([newRow]);
    }

    if (result.success) {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      toast.success(`The changes applied successfully`, {
        autoClose: 5000,
        position: "top-left",
      });
      return updatedRow;
    } else {
      setRowModesModel({ ...rowModesModel, [newRow.id]: { mode: GridRowModes.Edit } });
      toast.error(result.message, {
        autoClose: 5000,
        position: "top-left",
      });
    }
    
    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem className="saveButton actionBtn"
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem 
              icon={<CancelIcon />}
              label="Cancel"
              className="cancelButton actionBtn"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<ContentCopyIcon />}
            label="Copy"
            className="copyButton actionBtn"
            onClick={handleCopyClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="editButton actionBtn"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            className="deleteButton actionBtn"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ].concat(listOfColumns);

  const style = {
    ediTable: {
      height: outerHeight ? outerHeight : `${data.length===0 ? '50' : data.length<=5 ? '200' : '600'}px`
    }
  }

  return (
    <div className="ediTable" style={style.ediTable} >
      <ToastContainer />
      {/* <RUSure isOpen={openRUSure} setIfDelete={setIfDelete} 
                  whatToDelete={rowToDelete.txt} setOpenRUSure={setOpenRUSure} /> */}
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, getNextMaxId, 
                     makeEmptyRow, data, what }
        }}
      />
    </Box>
    </div>
  );
}

export default EdiTable;