import './MyTable2.scss';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { useMemo } from 'react';
import ExcelExport from './ExcelExport';

const MyTable = ({what, listOfColumns, data, deleteById, noActions,
                  columnsVisible, needsCheckboxes }) => {

  const memorizedData = useMemo(() => data, [data]);
  const memorizedColumns = useMemo(() => listOfColumns, [listOfColumns]);

    const style = {
      myTable: {
        height: `${data.length===0 ? '50' : data.length<=5 ? '200' : '600'}px`
      }
    }

    const handleDelete = (id) => {

      deleteById(id);

    }

    const actionColumn = [
        {
          field: "action",
          headerName: "Action",
          width: 200,
          renderCell: (params) => {
            return (
              <div className="cellAction">
                <Link to={`/#/test`} style={{ textDecoration: "none" }}>
                  <div className="viewButton">View</div>
                </Link>
                <div
                  className="deleteButton"
                  onClick={() => handleDelete(params.row.id)}
                >
                  Delete
                </div>
              </div>
            );
          },
        },
    ];
console.log(`WHAT: \n`,what)
console.log(`what.replaceAll(' ','_'):`,what.replaceAll(' ','_'))
    return (
        <div className="myTable" style={style.myTable} >
          <div className="myTableTitle">
            {what.split('').map((ch,i)=>i===0 ? ch.toUpperCase() 
                                              : ch.toLowerCase()).join('') + ':'}
              <ExcelExport data={data} filename={what.replaceAll(' ','_')} />
                                 
          </div>
          <DataGrid style={style.myTable} className="datagrid" getRowHeight={() => 'auto'}
              rows={memorizedData}
              columns={noActions ? memorizedColumns : memorizedColumns.concat(actionColumn)}
              pageSize={5}
              pageSizeOptions={[5,100]}
              rowsPerPageOptions={[5]}
              columnVisibilityModel={columnsVisible}
              checkboxSelection={needsCheckboxes}
          />
        </div>
    )
}

export default MyTable;