import { create } from 'zustand';

export const useCourseListStore = create((set,get) => ({

  courseList: {list:[],updated:0},
  examList: {list:[],updated:0,courseId: undefined},
  course: sessionStorage.getItem('class-course') && sessionStorage.getItem('class-course') !== 'undefined' ? 
            JSON.parse(sessionStorage.getItem('class-course')) : {},
  studentList: [],

  setCourse: (currCourse) => set((state) => {
    const newCourse = JSON.stringify(currCourse);
    const prevCourse = JSON.stringify(state.course);
    if (newCourse !== prevCourse && newCourse !== 'undefined') {
      sessionStorage.setItem('class-course', newCourse);
      return { course: currCourse };
    }
    return state;
  }),
  
  setStudentList: (list) => set({ studentList: list}), 
  setCourseList: (list) => set({ courseList: {list,updated:Date.now() }}), 
  setExamList: (list, courseId) => set({ examList: {list, courseId, updated:Date.now()} }), 
  updateExamList: (listToUpdate) => {
    const oldList = get().examList.list;
    const courseId = get().course.id;
    const list = oldList.map(ex => {
      let updatedEx = listToUpdate.filter(e => e.id === ex.id && e.course_id === ex.course_id);
      if (updatedEx.length === 0) return ex;
      return updatedEx[0];
    })
    set({ examList: {list, courseId, updated:Date.now()} })
  }, 
   
  addExamList: (newExamList) => set((state) => ({ 
    examList: {list:[...state.examList.list,...newExamList], 
      courseId:state.examList.courseId, updated:Date.now()} })),

  removeExam: (id) => set((state) => ({ 
    examList: {list: state.examList.list.filter(ex => ex.id !== id), 
      courseId:state.examList.courseId, updated:Date.now()} 
  })), 
  addExam: (exam) => set((state) => ({ 
    examList: [...state.examList, exam] 
  })), 

}))